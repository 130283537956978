import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, renderList as _renderList, normalizeStyle as _normalizeStyle, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/illustrations/no_cluster.svg'


const _hoisted_1 = {
  key: 0,
  class: "d-flex my-auto"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex my-auto token-text-medium mr-2"
}
const _hoisted_3 = {
  key: 2,
  class: "d-flex pr-2 mr-auto my-auto"
}
const _hoisted_4 = { class: "my-auto ellipsis cluster-name" }
const _hoisted_5 = {
  key: 0,
  class: "my-auto d-flex-row"
}
const _hoisted_6 = { class: "token-text-medium" }
const _hoisted_7 = {
  key: 1,
  class: "d-flex pr-2 mr-auto my-auto token-text-medium"
}
const _hoisted_8 = {
  key: 2,
  class: "d-flex ml-4 mr-auto my-auto token-text-medium"
}
const _hoisted_9 = {
  key: 3,
  class: "d-flex pr-2 mr-auto my-auto"
}
const _hoisted_10 = { class: "my-auto ellipsis cluster-name" }
const _hoisted_11 = { class: "token-text-medium" }
const _hoisted_12 = {
  key: 1,
  class: "select-cluster-view"
}
const _hoisted_13 = { class: "token-text-medium" }
const _hoisted_14 = { class: "token-text-font-iAWriterDuoV token-text-micro token-text-color-secondary mr-2" }
const _hoisted_15 = { class: "token-text-font-iAWriterDuoV token-text-micro token-text-color-primary" }
const _hoisted_16 = { class: "token-text-medium" }
const _hoisted_17 = { class: "token-text-font-iAWriterDuoV token-text-micro token-text-color-secondary mr-2" }
const _hoisted_18 = { class: "token-text-font-iAWriterDuoV token-text-micro token-text-color-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_ModalPurchaseCredit = _resolveComponent("ModalPurchaseCredit")!
  const _component_ModalValidatePaymentMethodList = _resolveComponent("ModalValidatePaymentMethodList")!
  const _component_ModalEditLimits = _resolveComponent("ModalEditLimits")!
  const _component_ModalClusterConfig = _resolveComponent("ModalClusterConfig")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_DeployButton = _resolveComponent("DeployButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_ListElement = _resolveComponent("ListElement")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ClusterDisplay = _resolveComponent("ClusterDisplay")!
  const _component_SubscriptionStartTrialModal = _resolveComponent("SubscriptionStartTrialModal")!
  const _component_SubscriptionTrialOverModal = _resolveComponent("SubscriptionTrialOverModal")!
  const _component_SubscriptionExpiredModal = _resolveComponent("SubscriptionExpiredModal")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["deployment-modal bar text-align-left", {open: _ctx.open || _ctx.isDraftView, reduced: _ctx.isReduced, finished: _ctx.isDeployFinished, deploying:_ctx.deploying}])
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          class: "ma-auto"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ModalPurchaseCredit, {
            visible: _ctx.showPurchaseCreditModal,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPurchaseCreditModal) = $event)),
            fullScreen: true
          }, null, 8, ["visible"]),
          _createVNode(_component_ModalValidatePaymentMethodList, {
            visible: _ctx.showValidatePaymentMethodList,
            "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showValidatePaymentMethodList) = $event)),
            fullScreen: true,
            onSuccess: _ctx.onSuccessValidatePaymentMethodList
          }, null, 8, ["visible", "onSuccess"]),
          _createVNode(_component_ModalEditLimits, {
            visible: _ctx.showEditLimits,
            "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showEditLimits) = $event)),
            fullScreen: true
          }, null, 8, ["visible"]),
          (_ctx.isSelectClusterView)
            ? (_openBlock(), _createBlock(_component_ModalClusterConfig, {
                key: 0,
                cluster: _ctx.selectedCluster,
                creating: _ctx.creatingCluster
              }, null, 8, ["cluster", "creating"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_col, { class: "pa-0" }, {
            default: _withCtx(() => [
              (_ctx.isReduced)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    class: "ma-0 pa-2 deployment-modal-header"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.open)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            (_ctx.deploying)
                              ? (_openBlock(), _createBlock(_component_CustomButton, {
                                  key: 0,
                                  class: "mr-2 my-auto",
                                  level: _ctx.$enums.ButtonLevels.SmallIcon,
                                  icon: "caret-left",
                                  onClick: _ctx.toggleReduce
                                }, null, 8, ["level", "onClick"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.hideNbChanges)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_CustomButton, {
                              class: "nb-changes",
                              level: _ctx.$enums.ButtonLevels.Tertiary,
                              onClick: _ctx.openDraftView
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.nbChanges) + " change(s)", 1)
                              ]),
                              _: 1
                            }, 8, ["level", "onClick"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.currentCluster && !_ctx.hideCluster)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (!_ctx.hideNbChanges)
                              ? (_openBlock(), _createBlock(_component_Divider, {
                                  key: 0,
                                  vertical: true,
                                  class: "mr-2"
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_CustomButton, {
                              class: "nb-changes",
                              level: _ctx.$enums.ButtonLevels.Tertiary,
                              onClick: _ctx.openSelectClusterView
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentCluster?.attributes?.name), 1),
                                _createVNode(_component_Badge, {
                                  class: "ml-2 my-auto",
                                  label: _ctx.$helpers.states.translateDeploymentStatusToLabel(_ctx.clustersStatus[_ctx.currentCluster.id]),
                                  severity: _ctx.$helpers.states.translateDeploymentStatusToSeverity(_ctx.clustersStatus[_ctx.currentCluster.id]),
                                  icon: _ctx.clustersStatus[_ctx.currentCluster.id] === _ctx.$enums.DeploymentStatus.DEPLOYING || _ctx.clustersStatus[_ctx.currentCluster.id] === _ctx.$enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : '',
                                  iconColor: "rgba(0, 0, 58, 1)"
                                }, null, 8, ["label", "severity", "icon"])
                              ]),
                              _: 1
                            }, 8, ["level", "onClick"])
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.myClusters.length)
                        ? (_openBlock(), _createBlock(_component_CustomButton, {
                            key: 3,
                            class: "ml-auto",
                            onClick: _ctx.openSelectClusterView
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("Select cluster")
                            ])),
                            _: 1
                          }, 8, ["onClick"]))
                        : (_openBlock(), _createBlock(_component_DeployButton, {
                            key: 4,
                            class: "ml-auto",
                            forceDisable: _ctx.getShouldDisablePublish,
                            draftViewText: _ctx.draftViewText,
                            versionsToDeploy: _ctx.versionsToDeploy
                          }, null, 8, ["forceDisable", "draftViewText", "versionsToDeploy"]))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_v_row, { class: "ma-0 pa-2 deployment-modal-header" }, {
                      default: _withCtx(() => [
                        (_ctx.open)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              (_ctx.planExpanded)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createVNode(_component_CustomButton, {
                                      class: "mr-4 my-auto",
                                      level: _ctx.$enums.ButtonLevels.SmallIcon,
                                      icon: "arrow-left",
                                      onClick: _ctx.closeExpandPlan
                                    }, null, 8, ["level", "onClick"]),
                                    _createVNode(_component_Logo, {
                                      logo: _ctx.getLogoFromDeploymentPlan(_ctx.planExpanded),
                                      class: "mr-2"
                                    }, null, 8, ["logo"]),
                                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.textFromDeploymentPlan(_ctx.planExpanded)), 1)
                                  ], 64))
                                : (_openBlock(), _createBlock(_component_CustomButton, {
                                    key: 1,
                                    class: "mr-2 my-auto",
                                    level: _ctx.$enums.ButtonLevels.SmallIcon,
                                    icon: "x",
                                    onClick: _ctx.toggleReduce
                                  }, null, 8, ["level", "onClick"]))
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.hideNbChanges)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_component_CustomButton, {
                                class: "nb-changes",
                                level: _ctx.$enums.ButtonLevels.Tertiary,
                                onClick: _ctx.openDraftView
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.nbChanges) + " change(s)", 1)
                                ]),
                                _: 1
                              }, 8, ["level", "onClick"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.isSelectClusterView)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Select cluster "))
                          : _createCommentVNode("", true),
                        (_ctx.currentCluster && !_ctx.hideCluster)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              (!_ctx.hideNbChanges)
                                ? (_openBlock(), _createBlock(_component_Divider, {
                                    key: 0,
                                    vertical: true,
                                    class: "mr-2"
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_CustomButton, {
                                class: "nb-changes",
                                level: _ctx.$enums.ButtonLevels.Tertiary,
                                onClick: _ctx.openSelectClusterView
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.currentCluster?.attributes?.name), 1),
                                  _createVNode(_component_Badge, {
                                    class: "ml-2 my-auto",
                                    label: _ctx.$helpers.states.translateDeploymentStatusToLabel(_ctx.clustersStatus[_ctx.currentCluster.id]),
                                    severity: _ctx.$helpers.states.translateDeploymentStatusToSeverity(_ctx.clustersStatus[_ctx.currentCluster.id]),
                                    icon: _ctx.clustersStatus[_ctx.currentCluster.id] === _ctx.$enums.DeploymentStatus.DEPLOYING || _ctx.clustersStatus[_ctx.currentCluster.id] === _ctx.$enums.DeploymentStatus.SHUTTING_DOWN ? 'spinner' : '',
                                    iconColor: "rgba(0, 0, 58, 1)"
                                  }, null, 8, ["label", "severity", "icon"])
                                ]),
                                _: 1
                              }, 8, ["level", "onClick"])
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.myClusters.length && !_ctx.isSelectClusterView)
                          ? (_openBlock(), _createBlock(_component_CustomButton, {
                              key: 4,
                              class: "ml-auto",
                              onClick: _ctx.openSelectClusterView
                            }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode("Select cluster")
                              ])),
                              _: 1
                            }, 8, ["onClick"]))
                          : (_ctx.isSelectClusterView)
                            ? (_openBlock(), _createBlock(_component_CustomButton, {
                                key: 5,
                                class: "ml-auto",
                                onClick: _ctx.saveClusterSelect,
                                disabled: !_ctx.tempClusterSelected || _ctx.currentProject?.attributes?.status !== _ctx.$enums.DeploymentStatus.OFFLINE
                              }, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createTextVNode("Save")
                                ])),
                                _: 1
                              }, 8, ["onClick", "disabled"]))
                            : (_openBlock(), _createBlock(_component_DeployButton, {
                                key: 6,
                                class: "ml-auto",
                                forceDisable: _ctx.getShouldDisablePublish,
                                draftViewText: _ctx.draftViewText,
                                versionsToDeploy: _ctx.versionsToDeploy
                              }, null, 8, ["forceDisable", "draftViewText", "versionsToDeploy"]))
                      ]),
                      _: 1
                    }),
                    (_ctx.open)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_ctx.isDraftView)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.deploymentPlansDraft, (deploymentPlan) => {
                                return (_openBlock(), _createBlock(_component_v_row, {
                                  key: 'draftrow-'+deploymentPlan.id,
                                  class: "deployment-plan-elem ma-0 mt-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Checkbox, {
                                      class: "ml-4",
                                      modelValue: _ctx.planSelectedToDeploy[deploymentPlan.id],
                                      "onUpdate:modelValue": [($event: any) => ((_ctx.planSelectedToDeploy[deploymentPlan.id]) = $event), ($event: any) => (_ctx.updateDeploymentPlanIncludeInVersion(deploymentPlan, $event))],
                                      disabled: _ctx.isKubernetesCluster(deploymentPlan)
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                                    _createVNode(_component_ListElement, {
                                      logo: _ctx.getLogoFromDeploymentPlan(deploymentPlan),
                                      noHover: true,
                                      class: "ml-4 list-elem-draft"
                                    }, {
                                      badge: _withCtx(() => [
                                        _createVNode(_component_v_row, { class: "ma-0" }, {
                                          default: _withCtx(() => [
                                            deploymentPlan
                                              ? (_openBlock(), _createBlock(_component_Badge, {
                                                  key: 0,
                                                  severity: _ctx.deploymentPlanBadgeSeverity(deploymentPlan),
                                                  label: _ctx.deploymentPlanBadgeLabel(deploymentPlan),
                                                  outlined: true,
                                                  icon: _ctx.deploymentPlanIcon(deploymentPlan)
                                                }, null, 8, ["severity", "label", "icon"]))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.textFromDeploymentPlan(deploymentPlan)), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["logo"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            : (_ctx.isSelectClusterView)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  (!_ctx.clusters?.length)
                                    ? (_openBlock(), _createBlock(_component_v_row, {
                                        key: 0,
                                        class: "ma-0 d-flex-row"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { class: "pa-0 ma-auto fit-content fit-content-height d-flex-col" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content-height mt-4" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_img, {
                                                    src: _imports_0,
                                                    height: "56px",
                                                    width: "72px"
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_row, { class: "ma-0 token-text-medium mt-4 mx-auto fit-content-height" }, {
                                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                  _createTextVNode(" No clusters available ")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_row, { class: "ma-0 token-text-regular token-text-color-secondary mb-4 mx-auto fit-content-height" }, {
                                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                  _createTextVNode(" Create a cluster to start deployment ")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content-height" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_CustomButton, {
                                                    icon: "add",
                                                    text: "Create cluster",
                                                    onClick: _ctx.createCluster
                                                  }, null, 8, ["onClick"])
                                                ]),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusters, (cluster) => {
                                          return (_openBlock(), _createBlock(_component_v_row, {
                                            key: 'clustertrow-'+cluster.id,
                                            class: "deployment-plan-elem ma-0 mt-4 mx-4"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ClusterDisplay, {
                                                cluster: cluster,
                                                clustersStatus: _ctx.clustersStatus[cluster.id],
                                                onClickMenu: _ctx.onBurgerMenuItemClick,
                                                onClickDisplay: _ctx.onClusterClick,
                                                selected: _ctx.tempClusterSelected?.id === cluster.id,
                                                simpleBurger: true
                                              }, null, 8, ["cluster", "clustersStatus", "onClickMenu", "onClickDisplay", "selected"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128)),
                                        _createVNode(_component_v_row, { class: "ma-0 new-cluster-footer" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_Divider),
                                            _createVNode(_component_CustomButton, {
                                              icon: "add",
                                              class: "full-width mt-4",
                                              text: "New cluster",
                                              onClick: _ctx.createCluster,
                                              level: _ctx.$enums.ButtonLevels.Secondary
                                            }, null, 8, ["onClick", "level"])
                                          ]),
                                          _: 1
                                        })
                                      ], 64))
                                ]))
                              : (_ctx.subscriptionState === _ctx.$enums.SubscriptionState.FRESH)
                                ? (_openBlock(), _createBlock(_component_SubscriptionStartTrialModal, { key: 2 }))
                                : (_ctx.subscriptionState === _ctx.$enums.SubscriptionState.TRIAL_EXPIRED)
                                  ? (_openBlock(), _createBlock(_component_SubscriptionTrialOverModal, { key: 3 }))
                                  : (_ctx.subscriptionState === _ctx.$enums.SubscriptionState.EXPIRED)
                                    ? (_openBlock(), _createBlock(_component_SubscriptionExpiredModal, { key: 4 }))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                        (_ctx.isRollingBack)
                                          ? (_openBlock(), _createBlock(_component_v_row, {
                                              key: 0,
                                              class: "ma-2 deployement-rollback-message"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, { class: "pa-0 fit-content ml-3 mt-4" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_Icon, { icon: "question-circle" })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_col, { class: "pa-3" }, {
                                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                    _createElementVNode("div", { class: "token-text-medium mb-1" }, " Deploy failed. Canceling deployment. ", -1),
                                                    _createElementVNode("div", { class: "token-text-regular" }, " We are canceling this deployment because one or more services failed to deploy. Your last working version will remain online. ", -1)
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (_ctx.planExpanded)
                                          ? (_openBlock(), _createBlock(_component_v_col, {
                                              key: 1,
                                              class: "pr-0 log-window"
                                            }, {
                                              default: _withCtx(() => [
                                                (_ctx.isLoadingLog)
                                                  ? (_openBlock(), _createBlock(_component_Icon, {
                                                      key: 0,
                                                      icon: "spinner"
                                                    }))
                                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPlanLogs(_ctx.planExpanded.id), (log) => {
                                                        return (_openBlock(), _createBlock(_component_v_row, {
                                                          class: "ma-0 mb-2",
                                                          key: log.id
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, { class: "pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content" }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(_ctx.getTimeToDisplay(log.attributes.time)), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, { class: "pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-primary" }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(log.attributes.message), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024))
                                                      }), 128)),
                                                      (_ctx.getRollingBackPlanFromPlan(_ctx.planExpanded))
                                                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getPlanLogs((_ctx.getRollingBackPlanFromPlan(_ctx.planExpanded) as APIDeploymentPlan).id), (log) => {
                                                            return (_openBlock(), _createBlock(_component_v_row, {
                                                              class: "ma-0 mb-2",
                                                              key: log.id
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_col, { class: "pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-secondary mr-2 fit-content" }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.getTimeToDisplay(log.attributes.time)), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1024),
                                                                _createVNode(_component_v_col, { class: "pa-0 token-text-font-iAWriterDuoV token-text-regular token-text-color-primary" }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(log.attributes.message), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1024)
                                                              ]),
                                                              _: 2
                                                            }, 1024))
                                                          }), 128))
                                                        : _createCommentVNode("", true),
                                                      (_ctx.planExpanded.attributes.status !== _ctx.$enums.DeploymentRunState.SUCCEEDED)
                                                        ? (_openBlock(), _createBlock(_component_Icon, {
                                                            key: 1,
                                                            icon: "spinner"
                                                          }))
                                                        : _createCommentVNode("", true),
                                                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "scroll-anchor" }, null, -1)),
                                                      _createVNode(_component_v_row, {
                                                        class: "ma-0 init-scroll",
                                                        style: _normalizeStyle({height:'calc(100vh - ' + (129 + (_ctx.getPlanLogs(_ctx.planExpanded.id).length * 32)) + 'px)'})
                                                      }, null, 8, ["style"])
                                                    ], 64))
                                              ]),
                                              _: 1
                                            }))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (deploymentPlan) => {
                                                return (_openBlock(), _createElementBlock("div", {
                                                  key: 'row-'+deploymentPlan.id,
                                                  class: "deployment-plan-elem mt-2"
                                                }, [
                                                  (_ctx.getRollingBackPlanFromPlan(deploymentPlan))
                                                    ? (_openBlock(), _createBlock(_component_ListElement, {
                                                        key: 0,
                                                        logo: _ctx.getLogoFromDeploymentPlan(deploymentPlan),
                                                        class: "ml-4",
                                                        onClick: ($event: any) => (_ctx.toggleExpandPlan(deploymentPlan))
                                                      }, _createSlots({
                                                        badge: _withCtx(() => [
                                                          _createVNode(_component_v_row, { class: "ma-0" }, {
                                                            default: _withCtx(() => [
                                                              deploymentPlan
                                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                    _createVNode(_component_Badge, {
                                                                      severity: _ctx.deploymentPlanBadgeSeverity(deploymentPlan),
                                                                      label: _ctx.deploymentPlanBadgeLabel(deploymentPlan),
                                                                      outlined: true,
                                                                      icon: _ctx.deploymentPlanIcon(deploymentPlan)
                                                                    }, null, 8, ["severity", "label", "icon"]),
                                                                    _createVNode(_component_Icon, {
                                                                      icon: "arrow-right",
                                                                      class: "mx-2"
                                                                    }),
                                                                    _createVNode(_component_Badge, {
                                                                      severity: _ctx.deploymentPlanBadgeSeverity(_ctx.getRollingBackPlanFromPlan(deploymentPlan) as APIDeploymentPlan),
                                                                      label: "Cancel",
                                                                      outlined: true,
                                                                      icon: _ctx.deploymentPlanIcon(_ctx.getRollingBackPlanFromPlan(deploymentPlan) as APIDeploymentPlan)
                                                                    }, null, 8, ["severity", "icon"])
                                                                  ], 64))
                                                                : _createCommentVNode("", true),
                                                              _createVNode(_component_Icon, {
                                                                icon: "caret-right",
                                                                class: "ml-2 caret"
                                                              })
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        default: _withCtx(() => [
                                                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.textFromDeploymentPlan(deploymentPlan)), 1)
                                                        ]),
                                                        _: 2
                                                      }, [
                                                        (_ctx.getRollingBackPlanFromPlan(deploymentPlan)?.attributes?.last_log_entry?.message)
                                                          ? {
                                                              name: "subtext",
                                                              fn: _withCtx(() => [
                                                                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.getTimeToDisplay(_ctx.getRollingBackPlanFromPlan(deploymentPlan)?.attributes?.last_log_entry?.time)), 1),
                                                                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.getRollingBackPlanFromPlan(deploymentPlan)?.attributes?.last_log_entry?.message), 1)
                                                              ]),
                                                              key: "0"
                                                            }
                                                          : undefined
                                                      ]), 1032, ["logo", "onClick"]))
                                                    : (_openBlock(), _createBlock(_component_ListElement, {
                                                        key: 1,
                                                        logo: _ctx.getLogoFromDeploymentPlan(deploymentPlan),
                                                        class: "ml-4",
                                                        onClick: ($event: any) => (_ctx.toggleExpandPlan(deploymentPlan))
                                                      }, _createSlots({
                                                        badge: _withCtx(() => [
                                                          _createVNode(_component_v_row, { class: "ma-0" }, {
                                                            default: _withCtx(() => [
                                                              deploymentPlan
                                                                ? (_openBlock(), _createBlock(_component_Badge, {
                                                                    key: 0,
                                                                    severity: _ctx.deploymentPlanBadgeSeverity(deploymentPlan),
                                                                    label: _ctx.deploymentPlanBadgeLabel(deploymentPlan),
                                                                    outlined: true,
                                                                    icon: _ctx.deploymentPlanIcon(deploymentPlan)
                                                                  }, null, 8, ["severity", "label", "icon"]))
                                                                : _createCommentVNode("", true),
                                                              _createVNode(_component_Icon, {
                                                                icon: "caret-right",
                                                                class: "ml-2 caret"
                                                              })
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        default: _withCtx(() => [
                                                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.textFromDeploymentPlan(deploymentPlan)), 1)
                                                        ]),
                                                        _: 2
                                                      }, [
                                                        (deploymentPlan.attributes?.last_log_entry?.message)
                                                          ? {
                                                              name: "subtext",
                                                              fn: _withCtx(() => [
                                                                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.getTimeToDisplay(deploymentPlan.attributes?.last_log_entry?.time)), 1),
                                                                _createElementVNode("span", _hoisted_18, _toDisplayString(deploymentPlan.attributes?.last_log_entry?.message), 1)
                                                              ]),
                                                              key: "0"
                                                            }
                                                          : undefined
                                                      ]), 1032, ["logo", "onClick"]))
                                                ]))
                                              }), 128)),
                                              (_ctx.isFirstDeployment && !_ctx.isSelectClusterView)
                                                ? (_openBlock(), _createBlock(_component_v_row, {
                                                    key: 0,
                                                    class: "ma-4 pa-4 bar first-deployment-alert"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_col, { class: "pa-0" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                                                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                              _createTextVNode(" Free trial credits ")
                                                            ])),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_row, { class: "ma-0 my-2 token-text-regular" }, {
                                                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                              _createTextVNode(" You have 5€ of free credits to try all of Fransys features without limits. Once you are ready, you can buy more credits and/or enable auto provisioning. ")
                                                            ])),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_row, { class: "ma-0" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_col, { class: "pa-0" }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_CustomButton, {
                                                                    text: "Buy prepaid credits",
                                                                    level: _ctx.$enums.ButtonLevels.Secondary,
                                                                    class: "full-width",
                                                                    onClick: _ctx.onClickPurchaseCredits
                                                                  }, null, 8, ["level", "onClick"])
                                                                ]),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_v_col, { class: "pa-0 pl-2" }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_CustomButton, {
                                                                    text: "Enable auto provisioning",
                                                                    level: _ctx.$enums.ButtonLevels.Primary,
                                                                    class: "full-width",
                                                                    onClick: _ctx.onClickEnableAutoProvisioning
                                                                  }, null, 8, ["level", "onClick"])
                                                                ]),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true)
                                            ], 64))
                                      ], 64))
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
            ]),
            _: 1
          })
        ], 64))
  ], 2))
}