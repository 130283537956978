import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "billingView full-view" }
const _hoisted_2 = { class: "token-text-regular my-auto" }
const _hoisted_3 = {
  key: 1,
  class: "token-text-medium ml-auto my-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPurchaseCredit = _resolveComponent("ModalPurchaseCredit")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_StripeWidgetPaymentMethods = _resolveComponent("StripeWidgetPaymentMethods")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_WidgetBillingDetails = _resolveComponent("WidgetBillingDetails")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_PaymentMethodsDisplay = _resolveComponent("PaymentMethodsDisplay")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalPurchaseCredit, {
      visible: _ctx.showPurchaseCreditModal,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPurchaseCreditModal) = $event))
    }, null, 8, ["visible"]),
    (_ctx.customer)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          visible: _ctx.showAddPaymentMethodModal,
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAddPaymentMethodModal) = $event)),
          closeOnExternalClick: true
        }, {
          header: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createElementVNode("div", null, " Add payment method ", -1)),
                _createVNode(_component_CustomButton, {
                  class: "ml-auto",
                  level: _ctx.$enums.ButtonLevels.SmallIcon,
                  icon: "x",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddPaymentMethodModal = false))
                }, null, 8, ["level"])
              ]),
              _: 1
            })
          ]),
          content: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-4" }, {
              default: _withCtx(() => [
                (_ctx.showAddPaymentMethodModal)
                  ? (_openBlock(), _createBlock(_component_StripeWidgetPaymentMethods, {
                      key: 0,
                      customer: _ctx.customer,
                      onSuccess: _ctx.onAddPaymentMethod
                    }, null, 8, ["customer", "onSuccess"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["visible"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, {
      visible: _ctx.showBillingDetailModal,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showBillingDetailModal) = $event)),
      closeOnExternalClick: true
    }, {
      header: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _cache[11] || (_cache[11] = _createElementVNode("div", null, " Edit billing details ", -1)),
            _createVNode(_component_CustomButton, {
              class: "ml-auto",
              level: _ctx.$enums.ButtonLevels.SmallIcon,
              icon: "x",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showBillingDetailModal = false))
            }, null, 8, ["level"])
          ]),
          _: 1
        })
      ]),
      content: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-4" }, {
          default: _withCtx(() => [
            (_ctx.showBillingDetailModal)
              ? (_openBlock(), _createBlock(_component_WidgetBillingDetails, {
                  key: 0,
                  customer: _ctx.customer,
                  onSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showBillingDetailModal = false))
                }, null, 8, ["customer"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h4" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" Billing ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0 token-text-h5" }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Auto provisining ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode(" Automatically pay your full resource usage bill at the end of the month ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_Switch, {
                  modelValue: _ctx.switchValue,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.switchValue) = $event)),
                  onChange: _ctx.onSwitchChangeValue,
                  disabled: _ctx.switchDisabled
                }, null, 8, ["modelValue", "onChange", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_Divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h5" }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" Payment methods ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 mb-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_Divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_PaymentMethodsDisplay, { paymentMethods: _ctx.paymentMethods }, null, 8, ["paymentMethods"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomButton, {
              text: "Add payment method",
              level: _ctx.$enums.ButtonLevels.Secondary,
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showAddPaymentMethodModal = true))
            }, null, 8, ["level"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h5" }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" Billing details ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 mb-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_Divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.customer?.attributes?.name)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "ma-0 mt-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.customer.attributes.name), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.customer.attributes.address), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.customer.attributes.city) + " " + _toDisplayString(_ctx.customer.attributes.postal_code), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.customer.attributes.country), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.customer.attributes.tax_id)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: "ma-0"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.customer.attributes.tax_id), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomButton, {
              text: "Edit billing details",
              level: _ctx.$enums.ButtonLevels.Secondary,
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showBillingDetailModal = true))
            }, null, 8, ["level"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h5" }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" Current billing period ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 fit-content ml-auto token-text-regular token-text-color-secondary" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentBillingPeriod.start.toLocaleDateString()) + " ", 1),
                    _createVNode(_component_Icon, {
                      class: "mx-1",
                      icon: "arrow-right",
                      color: "rgba(0, 0, 58, 0.6)"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.currentBillingPeriod.end.toLocaleDateString()), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 mb-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_Divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { class: "pa-0" }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode(" Credit consumed ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0" }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode(" Credit left ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0" }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode(" Estimated bill ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { class: "pa-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(_ctx.consumed, _ctx.currency)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.customer?.attributes?.balance ? _ctx.$helpers.prices.format(parseInt(_ctx.customer?.attributes?.balance), _ctx.currency) : "-"), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(_ctx.projectedUsage, _ctx.currency)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 d-flex fit-content ml-auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  class: "my-auto",
                  text: "Purchase credit",
                  level: _ctx.$enums.ButtonLevels.Secondary,
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showPurchaseCreditModal = true))
                }, null, 8, ["level"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h5" }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode(" Invoices ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 mb-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_Divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_Table, {
              values: _ctx.invoiceValues,
              colClasses: ['', '', 'fit-content']
            }, {
              value: _withCtx(({value, col, row}) => [
                (col === 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_2, _toDisplayString(value), 1),
                      _createVNode(_component_Badge, {
                        outlined: "",
                        severity: _ctx.$helpers.states.translateInvoiceStatusToSeverity(_ctx.invoices[row].attributes.status),
                        label: _ctx.$helpers.states.translateInvoiceStatusToLabel(_ctx.invoices[row].attributes.status),
                        class: "ml-4 my-auto"
                      }, null, 8, ["severity", "label"])
                    ], 64))
                  : (col === 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(value), 1))
                    : (col === 2)
                      ? (_openBlock(), _createBlock(_component_CustomButton, {
                          key: 2,
                          text: "Download",
                          level: _ctx.$enums.ButtonLevels.Tertiary,
                          class: "ml-4 fit-content my-auto",
                          disabled: !value,
                          onClick: ($event: any) => (_ctx.downloadInvoice(value))
                        }, null, 8, ["level", "disabled", "onClick"]))
                      : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["values"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}