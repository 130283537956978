import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_FransysSignature = _resolveComponent("FransysSignature")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomNumberInput = _resolveComponent("CustomNumberInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    closeOnExternalClick: true,
    "onUpdate:visible": _ctx.closeModal,
    width: "780px",
    fullScreen: _ctx.fullScreen
  }, _createSlots({
    content: _withCtx(() => [
      _createVNode(_component_v_row, { class: "pa-4 ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { class: "pa-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Burn rate limit ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomNumberInput, {
                            class: "full-width",
                            modelValue: _ctx.burnRateLimit,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.burnRateLimit) = $event)),
                            min: 0,
                            max: _ctx.customer?.attributes?.monthly_system_burn_rate_limit / 100
                          }, null, 8, ["modelValue", "max"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 token-text-micro mt-2" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" When your burn rate reaches this amount we will reduce your resource usage. ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "pa-0 fit-content mr-2 my-auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Icon, { icon: "info" })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Maximum amount : " + _toDisplayString(_ctx.$helpers.prices.format(_ctx.customer?.attributes?.monthly_system_burn_rate_limit, _ctx.currency)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { class: "pa-0 ml-8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Monthly spending hard limit ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomNumberInput, {
                            class: "full-width",
                            modelValue: _ctx.hardLimit,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hardLimit) = $event)),
                            min: 0,
                            max: _ctx.customer?.attributes?.monthly_system_hard_limit / 100
                          }, null, 8, ["modelValue", "max"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 token-text-micro mt-2" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" When your usage reaches this amount we will undeploy all your ressources to prevent overdraft. ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "pa-0 fit-content mr-2 my-auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Icon, { icon: "info" })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Maximum amount : " + _toDisplayString(_ctx.$helpers.prices.format(_ctx.customer?.attributes?.monthly_system_hard_limit, _ctx.currency)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CustomButton, {
                    level: _ctx.$enums.ButtonLevels.Primary,
                    class: "full-width",
                    onClick: _ctx.saveLimitsChange,
                    loading: _ctx.loading,
                    status: _ctx.buttonState
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                    ]),
                    _: 1
                  }, 8, ["level", "onClick", "loading", "status"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (_ctx.fullScreen)
      ? {
          name: "headerFullScreen",
          fn: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 my-auto mx-12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: "pa-0 fit-content my-auto",
                  style: {"position":"absolute","top":"60px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomButton, {
                      text: "Back",
                      icon: "caret-left",
                      level: _ctx.$enums.ButtonLevels.Secondary,
                      onClick: _ctx.closeModal
                    }, null, 8, ["level", "onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FransysSignature, {
                      vertical: true,
                      class: "ma-auto"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.fullScreen)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 token-text-h4" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("div", null, " Choose spending limits ", -1)
              ])),
              _: 1
            })
          ]),
          key: "1"
        }
      : {
          name: "header",
          fn: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0" }, {
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("div", null, " Edit limits ", -1)),
                _createVNode(_component_CustomButton, {
                  class: "ml-auto",
                  level: _ctx.$enums.ButtonLevels.SmallIcon,
                  icon: "x",
                  onClick: _ctx.closeModal
                }, null, 8, ["level", "onClick"])
              ]),
              _: 1
            })
          ]),
          key: "2"
        }
  ]), 1032, ["visible", "onUpdate:visible", "fullScreen"]))
}