import enums from '@/enums'
import { APICluster, APIClusterLocation, APIClusterRequirement, APIClusterTypologies, APIDeployment } from '@/typesAPI'
import helpers from '@/utils/helpers'
import store from '..'
import API from '@/api/wrapper'


const pullingState:{[key:string]:{[key:string]:boolean}} = {typology: {}, location: {}, clusterRequirement: {}}

type State = {
  clusterList: APICluster[] | null,
  typologies:{[key:string]:APIClusterTypologies[]}
  deployments:APIDeployment[]
  locations:{[key:string]:APIClusterLocation[]}
  clusterRequirements: {[key:string]:APIClusterRequirement[]}
}

const state: State = {
  clusterList: null,
  typologies: {},
  clusterRequirements: {},
  locations: {},
  deployments: []
}

const getters = {
  getClusters: (state:State) => {
    if(state.clusterList === null && store.getters['user/getCustomer']?.id) {
      API.clusters.getClustersByCustomer(store.getters['user/getCustomer']?.id)
      .then((res) => {
        state.clusterList = res.clusters
        state.deployments = res.deployments
      })
      return []
    }
    return state.clusterList
  },
  getClusterRequirementsByProvider: (state:State) => {
    return (provider:string) => {
      if(!state.clusterRequirements[provider]) {
        if(pullingState.clusterRequirement[provider] === true) {
          return []
        }
        pullingState.clusterRequirement[provider] = true
        API.clusters.getClusterRequirementsByProvider(provider)
        .then((res) => {
          state.clusterRequirements[provider] = res
        })
        .finally(() => {
          pullingState.clusterRequirement[provider] = false
        })
        return []
      }
      return state.clusterRequirements[provider]
    }
  },
  getClusterById: (state:State) => {
    return (id:string) => {
      const clusterList = store.getters['clusters/getClusters']
      return clusterList ? clusterList.find((obj:APICluster) => obj.id === id) : null
    }
  },
  getTypologiesByProvider: (state:State) => {
    return (provider:string) => {
      if(!state.typologies[provider]) {
        if(pullingState.typology[provider] === true) {
          return []
        }
        pullingState.typology[provider] = true
        API.clusters.getTypologiesByProvider(provider)
        .then((res) => {
          state.typologies[provider] = res
        })
        .finally(() => {
          pullingState.typology[provider] = false
        })
        return []
      }
      return state.typologies[provider]
    }
  },
  getLocationsByProvider: (state:State) => {
    return (provider:string) => {
      if(!state.locations[provider]) {
        if(pullingState.location[provider] === true) {
          return []
        }
        pullingState.location[provider] = true
        API.clusters.getLocationsByProvider(provider)
        .then((res) => {
          state.locations[provider] = res
        })
        .finally(() => {
          pullingState.location[provider] = false
        })
        return []
      }
      return state.locations[provider]
    }
  },
  getDeployments: (state:State) => {
    return state.deployments
  },
}

const mutations = {
  SET_CLUSTERS: (state:State, clusters:APICluster[]) => {
    state.clusterList = clusters
  },
  EDIT_CLUSTER: (state:State, cluster:APICluster) => {
    if(state.clusterList) {
      helpers.ressources.addMultipleRessourceToArray(state.clusterList, [cluster])
    } else {
      state.clusterList = [cluster]
    }
  },
  DELETE_CLUSTER: (state:State, cluster:APICluster) => {
    if(state.clusterList) {
      state.clusterList = state.clusterList.filter((c) => c.id !== cluster.id)
    }
  },
  EDIT_DEPLOYMENT: (state:State, deployment:APIDeployment) => {
    helpers.ressources.addMultipleRessourceToArray(state.deployments, [deployment])
  },
  DELETE_DEPLOYMENT: (state:State, deployment:APIDeployment) => {
    state.deployments = state.deployments.filter((d) => d.id !== deployment.id)
  },
  REFRESH_LOCATIONS: (state:State, provider:string) => {
    if(pullingState.location[provider] !== true) {
      pullingState.location[provider] = true
      API.clusters.getLocationsByProvider(provider)
      .then((res) => {
        state.locations[provider] = res
      })
      .finally(() => {
        pullingState.location[provider] = false
      })
    }
  },
}

const actions = {
  setClusters: (context:any,clusters:APICluster[]) => {
    context.commit('SET_CLUSTERS', clusters)
  },
  editCluster: (context:any,cluster:APICluster) => {
    context.commit('EDIT_CLUSTER', cluster)
  },
  refreshLocations: (context:any,provider:string) => {
    context.commit('REFRESH_LOCATIONS', provider)
  },
  deleteCluster: (context:any,cluster:APICluster) => {
    context.commit('DELETE_CLUSTER', cluster)
  },
  editDeployment: (context:any,deployment:APIDeployment) => {
    context.commit('EDIT_DEPLOYMENT', deployment)
  },
  deleteDeployment: (context:any,deployment:APIDeployment) => {
    context.commit('DELETE_DEPLOYMENT', deployment)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}