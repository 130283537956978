<template>
  <div class="billingView full-view">
    <!-- MODAL Purchase credits -->
    <ModalPurchaseCredit v-model:visible="showPurchaseCreditModal"/>

    <!-- MODAL Add payment method -->
    <Modal v-model:visible="showAddPaymentMethodModal" :closeOnExternalClick="true" v-if="customer">
      <template #header>
        <v-row class="ma-0">
          <div>
            Add payment method
          </div>
          <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="showAddPaymentMethodModal = false"/>
        </v-row>      
      </template>
      <template #content>
        <v-col class="pa-4">
          <StripeWidgetPaymentMethods :customer="customer" @success="onAddPaymentMethod" v-if="showAddPaymentMethodModal"/>
        </v-col>
      </template>
    </Modal>

    <!-- MODAL Billing detail -->
    <Modal v-model:visible="showBillingDetailModal" :closeOnExternalClick="true">
      <template #header>
        <v-row class="ma-0">
          <div>
            Edit billing details
          </div>
          <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="showBillingDetailModal = false"/>
        </v-row>      
      </template>
      <template #content>
        <v-col class="pa-4">
          <WidgetBillingDetails :customer="customer" @success="showBillingDetailModal = false" v-if="showBillingDetailModal"/>
        </v-col>
      </template>
    </Modal>

    <!-- View -->
    <v-col class="pa-0 ">
      <v-row class="ma-0">
        <v-col class="pa-0 token-text-h4">
          Billing
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-8">
        <v-col class="pa-0 ">
          <v-row class="ma-0 token-text-h5">
            Auto provisining
          </v-row>
          <v-row class="ma-0 token-text-regular">
            Automatically pay your full resource usage bill at the end of the month
          </v-row>
        </v-col>
        <v-col class="pa-0 ml-auto fit-content">
          <Switch v-model="switchValue" @change="onSwitchChangeValue" :disabled="switchDisabled"/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0 ">
          <Divider/>
        </v-col>
      </v-row>

      <!-- Payment methods -->
      <v-row class="ma-0 mt-8">
        <v-col class="pa-0 token-text-h5">
          Payment methods
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4 mb-2">
        <v-col class="pa-0 ">
          <Divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <!-- PAYEMENT METHODS HERE -->
        <PaymentMethodsDisplay :paymentMethods="paymentMethods" />
      </v-row>
      <v-row class="ma-0">
        <CustomButton text="Add payment method" :level="$enums.ButtonLevels.Secondary" @click="showAddPaymentMethodModal = true"/>
      </v-row>

       <!-- Billing details -->
       <v-row class="ma-0 mt-8">
        <v-col class="pa-0 token-text-h5">
          Billing details
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4 mb-2">
        <v-col class="pa-0 ">
          <Divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-2" v-if="customer?.attributes?.name">
        <v-col class="pa-0">
          <v-row class="ma-0">
            {{ customer.attributes.name }}
          </v-row>
          <v-row class="ma-0">
            {{ customer.attributes.address }}
          </v-row>
          <v-row class="ma-0">
            {{ customer.attributes.city }} {{ customer.attributes.postal_code }}
          </v-row>
          <v-row class="ma-0">
            {{ customer.attributes.country }}
          </v-row>
          <v-row class="ma-0" v-if="customer.attributes.tax_id">
            {{ customer.attributes.tax_id }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-2">
        <CustomButton text="Edit billing details" :level="$enums.ButtonLevels.Secondary" @click="showBillingDetailModal = true"/>
      </v-row>

      <!-- Current billing period -->
      <v-row class="ma-0 mt-8">
        <v-col class="pa-0 token-text-h5">
          Current billing period
        </v-col>
        <v-col class="pa-0 fit-content ml-auto token-text-regular token-text-color-secondary">
          <v-row class="ma-0">
            {{ currentBillingPeriod.start.toLocaleDateString() }} 
            <Icon class="mx-1" icon="arrow-right" color="rgba(0, 0, 58, 0.6)"/>
            {{ currentBillingPeriod.end.toLocaleDateString() }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4 mb-2">
        <v-col class="pa-0 ">
          <Divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0 token-text-regular">
        <v-col class="pa-0 ">
          <v-row class="ma-0">
            <v-col class="pa-0 ">
              Credit consumed
            </v-col>
            <v-col class="pa-0 ">
              Credit left
            </v-col>
            <v-col class="pa-0 ">
              Estimated bill
            </v-col>
          </v-row>
          <v-row class="ma-0 token-text-medium">
            <v-col class="pa-0 ">
              {{ $helpers.prices.format(consumed, currency) }}
            </v-col>
            <v-col class="pa-0 ">
              {{ customer?.attributes?.balance ? $helpers.prices.format(parseInt(customer?.attributes?.balance), currency) : "-" }}
            </v-col>
            <v-col class="pa-0 ">
              {{ $helpers.prices.format(projectedUsage, currency) }}
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pa-0 d-flex fit-content ml-auto">
          <CustomButton class="my-auto" text="Purchase credit" :level="$enums.ButtonLevels.Secondary" @click="showPurchaseCreditModal = true"/>
        </v-col>
      </v-row>


      <!-- Invoices -->
      <v-row class="ma-0 mt-8">
        <v-col class="pa-0 token-text-h5">
          Invoices
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4 mb-2">
        <v-col class="pa-0 ">
          <Divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <Table :values="invoiceValues" :colClasses="['', '', 'fit-content']">
          <template #value="{value, col, row}">
            <template v-if="col === 0">
              <div class="token-text-regular my-auto">
                {{ value }}
              </div>
              <Badge outlined 
                :severity="$helpers.states.translateInvoiceStatusToSeverity(invoices[row].attributes.status)"
                :label="$helpers.states.translateInvoiceStatusToLabel(invoices[row].attributes.status)"
                class="ml-4 my-auto"
              />
            </template>
            <template v-else-if="col === 1">
              <div class="token-text-medium ml-auto my-auto">{{ value }}</div>
            </template>
            <template v-else-if="col === 2">
              <CustomButton text="Download" :level="$enums.ButtonLevels.Tertiary" class="ml-4 fit-content my-auto" :disabled="!value" @click="downloadInvoice(value)"/>
            </template>
          </template>
        </Table>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import Switch from '@/components/UIElements/Switch.vue';
import Divider from '@/components/UIElements/Divider.vue';
import Table from '@/components/UIElements/Table.vue';
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Modal from '@/components/UIElements/Modal.vue';
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import { APICustomer, APIInvoice, APIPaymentMethod } from '@/typesAPI';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import WidgetBillingDetails from '@/components/UIElements/Widget/WidgetBillingDetails.vue';
import StripeWidgetPaymentMethods from '@/components/UIElements/Widget/StripeWidgetPaymentMethods.vue';
import ModalPurchaseCredit from '@/components/Modals/ModalPurchaseCredit.vue';
import PaymentMethodsDisplay from '@/components/UIElements/PaymentMethodsDisplay.vue';
import Badge from '@/components/UIElements/Badge.vue';
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    Switch,
    Divider,
    CustomButton,
    Table,
    Modal,
    CustomNumberInput,
    Icon,
    CustomTextInput,
    WidgetBillingDetails,
    StripeWidgetPaymentMethods,
    ModalPurchaseCredit,
    PaymentMethodsDisplay,
    Badge
  },
})
export default class BillingView extends Vue {

  switchValue = false
  showPurchaseCreditModal = false
  showAddPaymentMethodModal = false
  showBillingDetailModal = false
  switchDisabled = false
  creditAmountToAdd = 100

  loadingModalCTA = false


  initialBillingDetailValues = {}

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get currency():string {
    return this.customer?.attributes?.currency
  }

  get consumed():number {
    return this.$store.getters['billing/getRessourceUsed']
  }

  get currentBillingPeriod(): {end:Date,start:Date} {
    return this.$store.getters['billing/getCurrentBillingPeriod']
  }

  get projectedUsage():number {
    return this.$store.getters['billing/getProjectedUsage']
  }

  get invoices():APIInvoice[] {
    let invoices = this.$store.getters['billing/getInvoices']
    if(invoices?.length) {
      invoices = invoices.filter((invoice:APIInvoice) => invoice.attributes.status !== 'draft')
    }
    return invoices
  }

  get paymentMethods():APIPaymentMethod[] {
    return this.$store.getters['billing/getPaymentMethods']
  }

  get invoiceValues():string[][] {
    return this.invoices?.map((invoice) => {
      let col1 = ""
      if(invoice.attributes.type === 'tally') {
        col1 = "Monthly usage : from " + new Date(invoice.attributes.start).toLocaleDateString() + ' to ' + new Date(invoice.attributes.end).toLocaleDateString()
      } else if(invoice.attributes.type === 'credits') {
        const key0 = Object.keys(invoice.attributes.lines)[0]
        col1 = 'Prepaid credits : ' + invoice.attributes.lines[key0].period_value + ' credits'
      } else {
        const key0 = Object.keys(invoice.attributes.lines)[0]
        col1 = invoice.attributes.lines[key0].label + ' : ' + invoice.attributes.lines[key0].period_value + ' ' + invoice.attributes.lines[key0].period_unit
      }
      return [
        col1,
        this.$helpers.prices.format(invoice.attributes.amount_total, this.currency),
        invoice.attributes.receipt_pdf ? invoice.attributes.receipt_pdf : '' 
      ]
    })
  }

  downloadInvoice(url:string) {
    // create element <a> for download PDF
      const link = document.createElement('a');
      link.href = url;
      // link.target = '_blank';
      link.download = url.substring(url.lastIndexOf('/') + 1);

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  onSwitchChangeValue() {
    this.switchDisabled = true
    API.billing.editCustomer(this.customer?.id, {has_auto_provisioning_enabled : this.switchValue})
    .finally(() => {
      this.switchDisabled = false
    })
  }

  onAddPaymentMethod() {
    this.showAddPaymentMethodModal = false
  }

  @Watch('customer', {immediate: true})
  onCustomerChange() {
    if(this.customer) {
      this.switchValue = this.customer.attributes.has_auto_provisioning_enabled
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
