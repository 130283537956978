<template>
  <div class="stripe-widget-payment-methods full-width" >
    <!-- Display a payment form -->
      <div id="payment-element-payment-method">
        <!--Stripe.js injects the Payment Element-->
      </div>
      <v-row class="ma-0 mt-2">
        <template v-if="showCancel">
          <CustomButton :level="$enums.ButtonLevels.Secondary" text="Cancel" class="ml-auto mr-2" @click="$emit('cancel')"/>
        </template>
        <CustomButton text="Save payment method" :level="$enums.ButtonLevels.Primary" class="" :class="{'full-width' : !showCancel}" :disabled="submitDisabled" :loading="loading" @click="handleSubmit" />
      </v-row>   
      <div id="payment-message" v-if="messageContent"> {{ messageContent }}</div>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component';
import { loadStripe } from '@stripe/stripe-js/pure';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Switch from '@/components/UIElements/Switch.vue';
import API from '@/api/wrapper';
import { APIPaymentMethodSetupIntent } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

class Props {
  customer: APIPaymentMethodSetupIntent = prop({
    required: true,
  });
  showCancel?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    Switch
  },
})
export default class StripeWidgetPaymentMethods extends Vue.with(Props) {

  submitDisabled = false
  loading = false
  textHidden = false
  messageContent = ""

  stripe:any;
  elements:any;

  @Watch('customer', {immediate : true})
  onCustomerChange() {
    if(this.customer && !this.stripe && !this.loading) {
      this.setLoading(true)
      API.billing.addPaymentMethods(this.customer.id)
      .then(({setupIntent, stripePublicKey}) => {
        loadStripe(stripePublicKey)
        .then((newStripeObj) => {
          this.stripe = newStripeObj
          this.initialize(setupIntent.attributes.client_secret);
        })
      })
    }
  }

  initialize(clientSecret:string) {

    if(this.stripe) {
      //@ts-ignore
      this.elements = this.stripe.elements({ appearance: this.$helpers.static.stripeAppearance, clientSecret :clientSecret });
    }

    const paymentElementOptions = {
      layout: "tabs",
    };
    const paymentElement = this.elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element-payment-method");

    this.elements.getElement('payment').on('ready',() => {
      this.setLoading(false);
    })
   
  }

  handleSubmit(e:any) {
    if(this.stripe && this.elements) {
      e.preventDefault();
      this.setLoading(true);

      this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required'
      })
      .then((err:any) => {
        const { error } = err 
         // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" ) {
          this.showMessage(error.message);
        } else if (error.type !== "validation_error"){
          this.showMessage("An unexpected error occurred.");
        }
      })
      .finally(() => {
        this.setLoading(false);
        this.$store.dispatch('billing/invalidatePaymentMethods')
        this.$emit('success')
      })
    }
    
  }

  // ------- UI helpers -------

  showMessage(messageText:any) {
    this.messageContent = messageText
    setTimeout(() => {
      this.messageContent = ""
    }, 4000);
  }


  // Show a spinner on payment submission
  setLoading(isLoading:any) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.submitDisabled = true;
      this.loading = true;
      this.textHidden = true
    } else {
      this.submitDisabled = false;
      this.loading = false;
      this.textHidden = false
    }
  }
}
</script>

<style lang="scss" scoped>
#submit {
  z-index: 1;
  position: relative;
}
</style>
