<template>
  <div>
    <Modal :visible="visible" :loading="loading" @clickExternal="onClose">
      <template #header>
        <v-row class="ma-n2">
          <CustomTextInput v-model="name" class="mr-0" style="width: calc(100% - 32px);" :plain="true" :max="30"/>
          <CustomButton class="ml-auto mr-2 my-auto fit-content" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="onClose"/>
        </v-row>

      </template>


      <!-- Alert disable in trial -->
      <template v-if="isTrialing">
        <v-row class="ma-4 pa-4 alert-trial">
          <v-col class="pa-0">
            <v-row class="ma-0 token-text-medium">
              Cluster are not editable with the free plan
            </v-row>
            <v-row class="ma-0 token-text-regular">
              Upgrade your plan to edit the cluster or enable auto scaling
            </v-row>
            <v-row class="ma-0 mt-4">
              <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="upgradePlan" :loading="upgradePlanLoading">
                Upgrade plan
              </CustomButton>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <!-- Auto / Manual scaling -->
      <!-- <v-row class="ma-4 mb-2">
        <SegmentedControl v-model="scallingType" :tabs="{'0' : 'Auto scalling', '1' : 'Manual'}" @update:modelValue="scallingType = $event" :disabled="isTrialing"/>
      </v-row> -->

      <!-- Region -->
      <v-row class="ma-0 ml-4 mt-2 mb-2 token-text-regular">
        Region
      </v-row>
      <v-row class="ma-0 mx-4 mb-6">
        <CustomSelect placeholder="Select a value" :items="regionsListItems" v-model="regionsActualValue" @update:modelValue="onSelectRegion" />
      </v-row>

      <!-- Auto Scalling -->
      <template v-if="isAutoScalling">
        <v-row class="ma-0 ml-4 mb-6 token-text-regular">
          <div>
            Worker nodes
          </div>
          <div class="ml-2 token-text-color-secondary" v-if="masterNodes.length">
            + {{ masterNodes.length }} master node(s)
          </div>
        </v-row>
        <v-row class="ma-0 mx-4">
          <Slider :values="nbNodesActualRange" :step="1" :min="1" :max="maxNbNodes" @updateValue:values="nbNodesActualRange = $event" :range="true" :disabled="isTrialing"/>
        </v-row>
        <v-row class="ma-0 py-2 mx-4 mb-6 master-nodes-info bar" v-if="masterNodes.length">
          <v-col class="pa-0 fit-content">
            <Icon icon="info" color="black" class="mt-1 mx-2"/>
          </v-col>
          <v-col class="pa-0">
            <v-row class="ma-0 token-text-medium">
              Master nodes added to this cluster to prevent service loss
            </v-row>
            <v-row class="ma-0 token-text-regular">
              These items are required for your cluster to work properly
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-0 ml-4 mb-6 token-text-regular">
          Nodes Performance
        </v-row>
        <v-row class="ma-0 mx-4">
          <v-col class="pa-2 custom-thumb" v-if="typologies[nodesPerfActualRange[0]]">
            <v-row class="ma-0">
              {{ typologies[nodesPerfActualRange[0]].attributes.name }}
            </v-row>
            <v-row class="ma-0 token-text-color-secondary">
              {{ typologies[nodesPerfActualRange[0]].attributes.cores }} CPUs
            </v-row>
            <v-row class="ma-0 token-text-color-secondary">
              {{ typologies[nodesPerfActualRange[0]].attributes.memory }} RAM
            </v-row>
            <v-row class="ma-0 token-text-color-secondary" v-if="regionsActualValue?.value">
              {{ $helpers.prices.format(parseInt(typologies[nodesPerfActualRange[0]].attributes.prices[regionsActualValue.value].amount), typologies[nodesPerfActualRange[0]].attributes.prices[regionsActualValue.value].currency) }}
            </v-row>
          </v-col>
          <v-col class="pa-0"></v-col>
          <v-col class="pa-2 custom-thumb" v-if="typologies[nodesPerfActualRange[0]]">
            <v-row class="ma-0">
              {{ typologies[nodesPerfActualRange[1]].attributes.name }}
            </v-row>
            <v-row class="ma-0 token-text-color-secondary">
              {{ typologies[nodesPerfActualRange[1]].attributes.cores }} CPUs
            </v-row>
            <v-row class="ma-0 token-text-color-secondary">
              {{ typologies[nodesPerfActualRange[1]].attributes.memory }} RAM
            </v-row>
            <v-row class="ma-0 token-text-color-secondary" v-if="regionsActualValue?.value">
              {{ $helpers.prices.format(parseInt(typologies[nodesPerfActualRange[1]].attributes.prices[regionsActualValue.value].amount), typologies[nodesPerfActualRange[1]].attributes.prices[regionsActualValue.value].currency) }}
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-0 mx-4">
          <Slider :values="nodesPerfActualRange" :step="1" :min="0" :max="typologies.length - 1" :hideThumbLabels="true" @updateValue:values="nodesPerfActualRange = $event" :range="true" :disabled="isTrialing"/>
        </v-row>
      </template>

      <!-- Manual -->
      <template v-else>
        <v-row class="ma-0 ml-4 mb-6 token-text-regular">
          <div>
            Worker nodes
          </div>
          <div class="ml-2 token-text-color-secondary" v-if="masterNodes.length">
            + {{ masterNodes.length }} control node(s)
          </div>
        </v-row>
        <v-row class="ma-0 mx-4">
          <Slider :values="nbNodesActualRange" :step="1" :min="1" :max="maxNbNodes" @updateValue:values="nbNodesActualRange[0] = $event; nbNodesActualRange[1] = $event" :disabled="isTrialing"/>
        </v-row>
        <v-row class="ma-0 py-2 mx-4 mb-6 master-nodes-info bar" v-if="masterNodes.length">
          <v-col class="pa-0 fit-content">
            <Icon icon="info" color="black" class="mt-1 mx-2"/>
          </v-col>
          <v-col class="pa-0">
            <v-row class="ma-0 token-text-medium">
              Control nodes are added to this cluster for reliability
            </v-row>
            <v-row class="ma-0 token-text-regular">
              They are managed automatically, but do impact the cost
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-0 ml-4 mb-2 token-text-regular">
          Nodes Performance 
        </v-row>
        <v-row class="ma-0 mx-4 mb-2">
          <v-col class="pa-0" v-for="(typology, index) of typologies" :key="'typo-' + index" cols="6" >
            <div class="card-typo" :class="(index%2 === 0 ? ' mr-2 ' : ' ml-2 ') + (nodesPerfActualRange[0] === index ? ' selected ' : '') + (isTypoDisabled(typology) ? ' disabled ' : '')" @click="onClickTypoCard(typology, index)" v-if="!isTypoAbsent(typology)">
              <v-row class="ma-0">
                {{ typology.attributes.name }}
              </v-row>
              <v-row class="ma-0 token-text-color-secondary">
                {{ typology.attributes.cores }} CPUs
              </v-row>
              <v-row class="ma-0 token-text-color-secondary">
                {{ typology.attributes.memory }} RAM
              </v-row>
            </div>
          </v-col>
        </v-row>
      </template>
      
      <template #footer>
        <v-row class="pa-4 ma-0 flex">
          <v-col class="pa-0 my-auto fit-content">
            <v-row class="ma-0 token-text-medium token-text-color-primary">
              <PriceDisplay 
                :price="totalPriceMin" 
                :price2="totalPriceMax" 
                :currency="customer?.attributes.currency"
              />
            </v-row>
          </v-col>
          <v-col class="pa-0 fit-content ml-auto">
            <v-row class="ma-0">
              <CustomButton :level="$enums.ButtonLevels.Tertiary" text="Cancel" class="mr-2" @click="cancel" />
              <CustomButton :level="$enums.ButtonLevels.Secondary" :text="textAccept" class="mr-2" @click="accept(false)" :status="buttonStateSave" :loading="saving" :disabled="disableBtnEdit" />
              <CustomButton :level="$enums.ButtonLevels.Primary" :text="'Deploy cluster'" :icon="'publish'" @click="accept(true)" :status="buttonStatePublish" :loading="saving" :disabled="disableBtnPublish" />
            </v-row>
          </v-col>

        </v-row>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Slider from '@/components/UIElements/Slider.vue'
import SegmentedControl from '@/components/UIElements/SegmentedControl.vue'
import API from '@/api/wrapper'
import CustomSelect from '@/components/UIElements/CustomSelect.vue'
import PriceDisplay from '@/components/UIElements/PriceDisplay.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { ListItem } from '@/components/UIElements/List.vue';
import { APICluster, APIClusterTypologies, APIPriceRanges, APICustomer, APIClusterRequirement, APIClusterLocation } from '@/typesAPI'
import { Watch } from 'vue-property-decorator'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'



class Props {
  cluster: APICluster | null = prop({
    required: true,
  });
  creating?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Modal,
    CustomButton,
    Slider,
    SegmentedControl,
    CustomSelect,
    Icon,
    CustomTextInput,
    PriceDisplay
  },
})
export default class ModalClusterConfig extends Vue.with(Props) {
  regionsActualValue?:ListItem = {}

  name = "New cluster"
  loading = false
  saving = false
  buttonStateSave = ""
  buttonStatePublish = ""
  defaultText = "Save config"
  textAccept = this.defaultText
  scallingType = '1'
  maxNbNodes = 6
  nbNodesActualRange = [0,0]
  nodesPerfActualRange = [0,0]
  upgradePlanLoading = false
  disableBtnEdit = false
  disableBtnPublish = false
  closeTimeout:number | undefined = undefined
  successCloseDelay = 2500
  inited = false
  defaultProvider = 'hetzner'
  provider = this.defaultProvider
  timerRefreshLocalisations = 5 * 60 * 1000 //5min
  intervalRefreshLocations = 0

  @Watch('creating', {immediate: true})
  onCreateChange() {
    this.defaultText = "Save config"
    this.textAccept = this.defaultText
  }

  onClickTypoCard(typology:APIClusterTypologies, index:number) {
    if(!this.isTypoAbsent(typology) && !this.isTypoDisabled(typology)) {
      this.nodesPerfActualRange[0] = index
      this.nodesPerfActualRange[1] = index
    }
  }

  mounted(): void {
    clearInterval(this.intervalRefreshLocations)
    this.$store.dispatch('clusters/refreshLocations', this.provider)
    this.intervalRefreshLocations = setInterval(() => {
      this.$store.dispatch('clusters/refreshLocations', this.provider)
    }, this.timerRefreshLocalisations)
  }

  unmounted(): void {
    clearInterval(this.intervalRefreshLocations)
  }

  init(): void {
    this.nbNodesActualRange = [3,6] //Default sliders value
    this.nodesPerfActualRange = [0,1] //Default sliders value
    this.regionsActualValue = this.regionsListItems[1]    
    this.inited = true
  }

  get regionsListItems ():ListItem[] {
    const ret:ListItem[] = []

    this.locations.forEach((location:APIClusterLocation) => {
      const newItem:ListItem = {
        text: this.$helpers.providers.locationToDisplayName(location),
        value: location.attributes.name,
        flag: location.attributes.country,
      }
      if(location === this.currentLocation) {
        newItem.selected = true
      }
      ret.push(newItem)
    })

    return ret.sort((a,b) => {
      if(a.text && b.text) {
        return a.text.localeCompare(b.text)
      }
      return 0
    })
  }

  get currentLocation ():APIClusterLocation | undefined {
    return this.locations.find((location) => location.attributes.name === this.regionsActualValue?.value)
  }

  upgradePlan() {
    this.$helpers.subscription.checkout();
    this.upgradePlanLoading = true
  }

  isTypoAbsent(typology:APIClusterTypologies) {
    return !(this.regionsActualValue?.value && typology?.attributes.prices[this.regionsActualValue?.value])
  }
  isTypoDisabled(typology:APIClusterTypologies) {
    if(this.regionsActualValue?.value && this.currentLocation) {
      if(this.currentLocation.attributes.available_server_typologies.find((typoName) => typoName === typology.id)) {
        return false
      }
    }
    return true
  }
  onClose() {
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: false})

    // Reset modal state after fading out
    setTimeout(() => {
      this.onclusterChange()
    }, 300);
  }

  cancel () {
    this.onClose()
  }

  accept (publishNow:boolean) {
    this.$store.dispatch('clusters/refreshLocations', this.provider)
    if(this.cluster && this.regionsActualValue?.value) {
      this.saving = true
      API.clusters.editCluster(this.cluster.id, 
        {
          name: this.name,
          deploy_now: publishNow ? true : false,
          requested_config:{
            node_number_max: this.nbNodesActualRange[1],
            node_number_min: this.nbNodesActualRange[0],
            node_size_max:this.typologies[this.nodesPerfActualRange[1]].id,
            node_size_min:this.typologies[this.nodesPerfActualRange[0]].id,
            region: this.regionsActualValue?.value,
          }
      })
      .then((newCluster:APICluster) => {
        this.$store.dispatch('clusters/editCluster', newCluster)

        if(publishNow) {
          this.buttonStatePublish = this.$enums.ButtonStates.Success
          this.buttonStateSave = this.$enums.ButtonStates.Neutral
        } else {
          this.buttonStatePublish = this.$enums.ButtonStates.Neutral
          this.buttonStateSave = this.$enums.ButtonStates.Success
        }

        this.textAccept = "Cluster updated"
        this.disableBtnEdit = true
        this.disableBtnPublish = true
        this.closeTimeout = setTimeout(() => {
          this.onClose()
        }, this.successCloseDelay);
      })
      .finally(() => {
        this.saving = false
      })
    } else if(this.creating && this.customer && this.regionsActualValue?.value) {
      this.saving = true
      API.clusters.create(this.customer.id, 
        {
          name: this.name,
          deploy_now: publishNow ? true : false,
          requested_config:{
            node_number_max: this.nbNodesActualRange[1],
            node_number_min: this.nbNodesActualRange[0],
            node_size_max:this.typologies[this.nodesPerfActualRange[1]].id,
            node_size_min:this.typologies[this.nodesPerfActualRange[0]].id,
            region: this.regionsActualValue?.value,
          }
      })
      .then((newCluster:APICluster) => {
        this.$store.dispatch('clusters/editCluster', newCluster)

        if(publishNow) {
          this.buttonStatePublish = this.$enums.ButtonStates.Success
          this.buttonStateSave = this.$enums.ButtonStates.Neutral
        } else {
          this.buttonStatePublish = this.$enums.ButtonStates.Neutral
          this.buttonStateSave = this.$enums.ButtonStates.Success
        }

        this.textAccept = "Cluster created"
        this.disableBtnEdit = true
        this.disableBtnPublish = true
        this.closeTimeout = setTimeout(() => {
          this.onClose()
        }, this.successCloseDelay);
      })
      .finally(() => {
        this.saving = false
      })
    }
  }


  onSelectRegion(newVal:ListItem) {
    this.regionsActualValue = newVal
  }

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get visible():boolean {
    return this.$store.getters['modals/getVisibilityCluster']
  }

  get isAutoScalling():boolean {
    return this.scallingType === '0'
  }

  get typologies():APIClusterTypologies[] {
    if(!this.provider) {
      return []
    }
    return this.$store.getters['clusters/getTypologiesByProvider'](this.provider)
  }

  get totalPriceMin():number {
    let typoPrice = 0
    if(this.typologies[this.nodesPerfActualRange[0]] && this.regionsActualValue?.value) {
      const priceWorkerNodes = this.nbNodesActualRange[0] * parseInt(this.typologies[this.nodesPerfActualRange[0]].attributes.prices[this.regionsActualValue.value]?.amount)
      const priceMasterNodes = this.masterNodes.length ? this.masterNodes.length * parseInt(this.masterNodes[0].attributes.prices[this.regionsActualValue.value]?.amount) : 0
      typoPrice = priceWorkerNodes + priceMasterNodes
    }
    return typoPrice
  }

  get totalPriceMax():number {
    if(!this.isAutoScalling) {
      return this.totalPriceMin
    }
    let typoPrice = 0
    if(this.typologies[this.nodesPerfActualRange[1]] && this.regionsActualValue?.value) {
      const priceWorkerNodes = this.nbNodesActualRange[1] * parseInt(this.typologies[this.nodesPerfActualRange[1]].attributes.prices[this.regionsActualValue.value]?.amount)
      const priceMasterNodes = this.masterNodes.length ? this.masterNodes.length * parseInt(this.masterNodes[0].attributes.prices[this.regionsActualValue.value]?.amount) : 0
      typoPrice = priceWorkerNodes + priceMasterNodes
    }
    return typoPrice
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get isSubscribed () {
    return this.subscriptionState !== this.$enums.SubscriptionState.FRESH && this.subscriptionState !== this.$enums.SubscriptionState.TRIAL_EXPIRED && this.subscriptionState !== this.$enums.SubscriptionState.EXPIRED
  }

  get isTrialing () {
    return this.subscriptionState === this.$enums.SubscriptionState.TRIALING
  }

  get providerRequirements ():APIClusterRequirement[] {
    return this.$store.getters['clusters/getClusterRequirementsByProvider'](this.provider)
  }

  get locations ():APIClusterLocation[] {
    return this.$store.getters['clusters/getLocationsByProvider'](this.provider)
  }

  get masterNodes ():APIClusterTypologies[] {
    return this.$helpers.clusters.getMasterNodesFromRequirements(this.providerRequirements, this.typologies, this.nbNodesActualRange[0], this.nbNodesActualRange[1])
  }

  


  @Watch("cluster", {immediate:true})
  onclusterChange() {
    if(!this.inited) {
      this.init()
    }
    clearInterval(this.closeTimeout)
    this.buttonStatePublish = this.$enums.ButtonStates.Neutral
    this.buttonStateSave = this.$enums.ButtonStates.Neutral
    this.disableBtnEdit = false
    this.disableBtnPublish = false
    this.textAccept = this.defaultText

    if(this.cluster) {
      // if(this.cluster?.attributes.requested_config.node_number_min !== this.cluster?.attributes.requested_config.node_number_max) {
      //   this.scallingType = '0'
      // }
      this.nbNodesActualRange = [this.cluster?.attributes.requested_config.node_number_min, this.cluster?.attributes.requested_config.node_number_max]
      const indexPerfMin = this.typologies.findIndex((typo:APIClusterTypologies) => {
        return typo.id === this.cluster?.attributes.requested_config.node_size_min
      })
      const indexPerfMax = this.typologies.findIndex((typo:APIClusterTypologies) => {
        return typo.id === this.cluster?.attributes.requested_config.node_size_max
      })
      this.nodesPerfActualRange = [indexPerfMin, indexPerfMax]

      if(this.cluster?.attributes.region) {
        this.regionsActualValue = this.regionsListItems.find((region:ListItem) => {
          return region.value === this.cluster?.attributes.region
        })
      }
      this.name = this.cluster?.attributes?.name
      this.provider = this.cluster?.attributes?.provider ? this.cluster?.attributes?.provider : this.defaultProvider
    } else {
      this.name = "New cluster"
      this.nbNodesActualRange = [3,3] //Default sliders value, if autoscalling available => [3,6]
      this.nodesPerfActualRange = [1,1] //Default sliders value, if autoscalling available => [0,1]
      this.scallingType = "1"
      this.provider = this.defaultProvider
    }
  }

  @Watch("typologies", {immediate:true})
  onTypologiesChange() {
    if(this.typologies?.length) {
      this.onclusterChange()
    }
  }

  @Watch("regionsActualValue")
  onRegionChange() {
    if(this.isTypoAbsent(this.typologies[this.nodesPerfActualRange[0]])) {
      this.nodesPerfActualRange = [0,0]
    }
  }

  @Watch("regionsListItems")
  onRegionsListItemsChange(newVal:APIClusterLocation[], oldVal:APIClusterLocation[]) {
    if(oldVal.length === 0 && newVal.length > 0) {
      this.regionsActualValue = newVal[0]
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
.custom-thumb {
  width: 128px;
  max-width: 128px;
  border:1px solid $color-neutral-grey-12;
  border-radius: 8px;
}
.card-typo {
  border:1px solid $color-neutral-grey-12;
  border-radius: 8px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  padding: 12px;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    border: 1px solid $color-neutral-grey-24;
    box-shadow: none;
  }
  
  &.disabled {
    border:1px solid $color-neutral-grey-12;
    background: $color-neutral-grey-12;
    cursor: default !important;
  }
  &.selected {
    padding-top: 11px;
    padding-left: 11px;
    margin-bottom: 15px;
    border: 2px solid $color-brand;
    box-shadow: 0px 0px 0px 2px rgba(50, 25, 178, 0.24);
  }
}
.alert-trial {
  border: 1px solid $color-orange;
  color: $text-error;
  background: $bg-error;
  border-radius: 8px;
}
.master-nodes-info {
  background-color: $color-neutral-grey-8 !important;
  width: auto !important;

}
</style>
