<template>
  <div class="ressourceUsageView full-view">
    <!-- MODAL Purchase credits -->
    <ModalPurchaseCredit v-model:visible="showPurchaseCreditModal"/>
    <!-- MODAL Edit limits -->
    <ModalEditLimits v-model:visible="showEditLimits" />

    <v-col class="pa-0 ">
      <v-row class="ma-0">
        <v-col class="pa-0 token-text-h4">
          Ressource usage
        </v-col>
      </v-row>
      
      <!-- Row 1 -->
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0">
          <div class="full-view bar full-width pa-4">
            <v-col class="pa-0 ">
              <v-row class="ma-0">
                <v-col class="pa-0 ">
                  <v-row class="ma-0 token-text-h5">
                    Balance
                  </v-row>
                  <v-row class="ma-0 token-text-h4">
                    {{ $helpers.prices.format(balance, currency) }}
                  </v-row>
                </v-col>
                <v-col class="pa-0 my-auto ml-auto fit-content">
                  <CustomButton text="Buy credit" :level="$enums.ButtonLevels.Secondary" @click="showPurchaseCreditModal = true" />
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>

      <!-- Row 2 -->
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0 mr-4">
          <div class="full-view bar full-width pa-4">
            <v-col class="pa-0 ">
              <v-row class="ma-0">
                <v-col class="pa-0 ">
                  <v-row class="ma-0">
                    <div class="token-text-h5 my-auto">
                      Burn rate
                    </div>
                    <CustomButton class="ml-auto" text="Edit limits" :level="$enums.ButtonLevels.Secondary" @click="showEditLimits = true"/>
                  </v-row>
                  <v-row class="ma-0 token-text-h4 fit-content">
                    <PriceDisplay :price="burnRate?.attributes?.amount ? parseInt(burnRate?.attributes?.amount) : 0" :currency="currency"/>
                  </v-row>
                  <v-row class="ma-0">
                    <div class="token-text-regular mr-1">Limit</div>
                    <div class="token-text-medium">{{$helpers.prices.format(customer?.attributes?.monthly_burn_rate_limit, currency)}}</div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
        <v-col class="pa-0">
          <div class="full-view bar full-width pa-4">
            <v-col class="pa-0 ">
              <v-row class="ma-0">
                <v-col class="pa-0 ">
                  <v-row class="ma-0 token-text-h5">
                    <div class="token-text-h5 my-auto">
                      Projected usage
                    </div>
                    <CustomButton class="ml-auto" text="Edit limits" :level="$enums.ButtonLevels.Secondary" @click="showEditLimits = true"/>
                  </v-row>
                  <v-row class="ma-0 token-text-h4 fit-content">
                    <PriceDisplay :price="projectedUsage" :currency="currency"/>
                  </v-row>
                  <v-row class="ma-0">
                    <div class="token-text-regular mr-1">Limit</div>
                    <div class="token-text-medium">{{$helpers.prices.format(customer?.attributes?.monthly_hard_limit, currency)}}</div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
      <!-- Row 3 -->

      <v-row class="ma-0 mt-4">
        <v-col class="pa-0">
          <div class="full-view bar full-width pa-4">
            <v-col class="pa-0 ">
              <v-row class="ma-0 token-text-h5">
                Ressource usage
              </v-row>
              <!-- Slider -->
              <v-row class="ma-0 mt-6 mb-4">
                <FlatGauge :values="flatGaugeValues" :colors="flatGaugeColors" />
              </v-row>
              <!-- Slider explained -->
              <v-row class="ma-0 mx-2 mb-6">
                <v-col class="pa-0 ">
                  <v-row class="ma-0">
                    <v-col class="pa-0 fit-content ">
                      <v-row class="ma-0 ml-auto mr-0" >
                        <Indicator :color="flatGaugeColors[0]"/>
                      </v-row>
                    </v-col>
                    <v-col class="pa-0 ">
                      <v-row class="ma-0 token-text-regular">
                        Ressource used
                      </v-row>
                      <v-row class="ma-0 token-text-medium">
                        {{ $helpers.prices.format(used, currency) }}
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pa-0 ">
                  <v-row class="ma-0 d-flex">
                    <v-col class="pa-0 fit-content ">
                      <v-row class="ma-0 ml-auto mr-0" >
                        <Indicator :color="flatGaugeColors[1]"/>
                      </v-row>
                    </v-col>
                    <v-col class="pa-0 my-auto">
                      <v-row class="ma-0 token-text-regular">
                        Projected usage
                      </v-row>
                      <v-row class="ma-0 token-text-medium">
                        {{ $helpers.prices.format(projectedUsage,currency) }}
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pa-0 ">
                  <v-row class="ma-0">
                    <v-col class="pa-0 fit-content ">
                      <v-row class="ma-0 ml-auto mr-0" >
                        <Indicator :color="flatGaugeColors[2]"/>
                      </v-row>
                    </v-col>
                    <v-col class="pa-0 ">
                      <v-row class="ma-0 token-text-regular">
                        Usage hard limit
                      </v-row>
                      <v-row class="ma-0 token-text-medium">
                        {{ $helpers.prices.format(customer?.attributes.monthly_hard_limit,currency) }}
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="ma-0 alert-msg mt-4" v-if="isProjectedOverLimit">
                <v-col class="pa-0 fit-content mr-2 mt-1">
                  <Icon icon="warning"/>
                </v-col>
                <v-col class="pa-0">
                  <v-row class="ma-0 token-text-medium">
                    Projected use is over the monthly spending hard limit. You might face service loss.
                  </v-row>
                  <v-row class="ma-0 token-text-regular">
                    You can increase the limit or reduce your resource size to avoid service loss.
                  </v-row>
                </v-col>
              </v-row>

            </v-col>
          </div>
        </v-col>
      </v-row>
      
      <!-- Row 4 -->
      <!-- <v-row class="ma-0 mt-8 ">
        <v-col class="pa-0 token-text-h5">
          Usage alerts
        </v-col>
        <v-col class="pa-0 fit-content ml-auto token-text-regular token-text-color-secondary">
          Send an alert to <b>{{ user?.attributes.email }}</b>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0">
          <Divider />
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-2" v-for="(usageAlert, index) of usageAlerts" :key="'usage-alert-'+index">
          <UsageAlert :usageAlert="usageAlert" />
      </v-row> -->
      <!-- Row 5 -->
      <!-- <v-row class="ma-0 mt-8 ">
        <v-col class="pa-0 token-text-h5">
          Resource usage detail         
        </v-col>
        <v-col class="pa-0 fit-content ml-auto token-text-regular token-text-color-secondary">
          <v-row class="ma-0">
            {{ currentBillingPeriod.start.toLocaleDateString() }} 
            <Icon class="mx-1" icon="arrow-right" color="rgba(0, 0, 58, 0.6)"/>
            {{ currentBillingPeriod.end.toLocaleDateString() }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4">
        <v-col class="pa-0">
          <Divider />
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-2">
        <Table :values="detailValues" :headers="detailHeaders">
          <template #header="{header, index}">
            <template v-if="index === 0">
              <div>{{ header }}</div>
            </template>
            <template v-else-if="index === 1 || index === 2">
              <div class="ml-auto fit-content">{{ header }}</div>
            </template>
          </template>
          <template #value="{value, col}">
            <template v-if="col === 0">
              <div class="token-text-regular my-auto">{{ value }}</div>
            </template>
            <template v-else-if="col === 1">
              <div class="token-text-medium ml-auto my-auto">{{ value }}</div>
            </template>
          </template>
        </Table>
      </v-row> -->
    </v-col>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Divider from '@/components/UIElements/Divider.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Table from '@/components/UIElements/Table.vue';
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue';
import UsageAlert from '@/components/UIElements/UsageAlert.vue';
import FlatGauge from '@/components/UIElements/FlatGauge.vue';
import Indicator from '@/components/UIElements/Indicator.vue';
import PriceDisplay from '@/components/UIElements/PriceDisplay.vue';

import ModalPurchaseCredit from '@/components/Modals/ModalPurchaseCredit.vue';
import ModalEditLimits from '@/components/Modals/ModalEditLimits.vue';


import { Vue, Options } from 'vue-class-component';
import { APICustomer, APIMoney, APIPrice, APIUser } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    Divider,
    Icon,
    Table,
    CustomNumberInput,
    UsageAlert,
    FlatGauge,
    Indicator,
    ModalPurchaseCredit,
    ModalEditLimits,
    PriceDisplay
  },
})
export default class RessourceUsageView extends Vue {

  sliderValues = [0, 0]
  showPurchaseCreditModal = false
  loadingLimitChange = false
  showEditLimits = false

  flatGaugeColors = ['rgba(50, 25, 178, 1)', 'rgba(157, 145, 218, 1)', 'rgba(206, 200, 237, 1)']

  usageAlerts = [
    {
      value: 80,
      limit: "hl",
      readonly: true 
    },
    {
      value: 50,
      limit: "hl",
      readonly: true 
    },
    {
      value: 80,
      limit: "brl",
      readonly: true 
    },
    {
      value: 50,
      limit: "brl",
      readonly: false 
    },
  ]

  detailHeaders = ['Resource name', 'Current usage price']

  get flatGaugeValues() {
    if(this.customer?.attributes?.monthly_hard_limit <= this.used ) {
      return [0, this.used]
    } else  if(this.customer?.attributes?.monthly_hard_limit <= this.projectedUsage ) {
      return [0,this.used, this.customer?.attributes?.monthly_hard_limit]
    }
    return [0,this.used,this.projectedUsage, this.customer?.attributes?.monthly_hard_limit]
  }

  get currentBillingPeriod(): {end:Date,start:Date} {
    return this.$store.getters['billing/getCurrentBillingPeriod']
  }

  get detailValues():string[][] {
    if(!this.currentBillingCycle) {
      return [[]]
    }
    return this.currentBillingCycle.map((price:APIPrice) => {
      return [
        price.attributes.label,
        this.$helpers.prices.format(parseInt(price.attributes.amount), this.currency),
      ]
    })
  }

  get projectedUsage():number {
    return this.$store.getters['billing/getProjectedUsage']
  }

  get isProjectedOverLimit() {
    return this.customer?.attributes.monthly_hard_limit ? this.projectedUsage > this.customer?.attributes.monthly_hard_limit : false
  }

  get balance() {
    return this.customer?.attributes?.balance ? parseInt(this.customer?.attributes?.balance) : 0
  }
  get currency() {
    return this.customer?.attributes?.currency
  }
  
  get burnRate():APIMoney | null {
    return this.$store.getters['billing/getBurnRate']
  }

  get currentBillingCycle():APIPrice[] {
    return this.$store.getters['billing/getCurrentBillingCycle']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get used():number {
    return this.$store.getters['billing/getRessourceUsed']
  }

  get user():APIUser {
    return this.$store.getters['user/getUser']
  }

  mounted(): void {
    this.sliderValues = [this.used, this.projectedUsage]
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.alert-msg {
  border: 1px solid $color-yellow;
  background: $bg-warning;
  color: $text-warning;
  border-radius: 8px;
  padding: 8px;
}

:deep(.v-slider-thumb__surface) {
  display: none !important;
}
:deep(.v-slider__container) {
  opacity: 1;
}

:deep(.v-range-slider) {
  pointer-events: none;
}
</style>
