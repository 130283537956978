import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ressourceUsageView full-view" }
const _hoisted_2 = { class: "full-view bar full-width pa-4" }
const _hoisted_3 = { class: "full-view bar full-width pa-4" }
const _hoisted_4 = { class: "token-text-medium" }
const _hoisted_5 = { class: "full-view bar full-width pa-4" }
const _hoisted_6 = { class: "token-text-medium" }
const _hoisted_7 = { class: "full-view bar full-width pa-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPurchaseCredit = _resolveComponent("ModalPurchaseCredit")!
  const _component_ModalEditLimits = _resolveComponent("ModalEditLimits")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_PriceDisplay = _resolveComponent("PriceDisplay")!
  const _component_FlatGauge = _resolveComponent("FlatGauge")!
  const _component_Indicator = _resolveComponent("Indicator")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalPurchaseCredit, {
      visible: _ctx.showPurchaseCreditModal,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPurchaseCreditModal) = $event))
    }, null, 8, ["visible"]),
    _createVNode(_component_ModalEditLimits, {
      visible: _ctx.showEditLimits,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showEditLimits) = $event))
    }, null, 8, ["visible"]),
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h4" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Ressource usage ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "ma-0 token-text-h5" }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode(" Balance ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_row, { class: "ma-0 token-text-h4" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(_ctx.balance, _ctx.currency)), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "pa-0 my-auto ml-auto fit-content" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CustomButton, {
                                text: "Buy credit",
                                level: _ctx.$enums.ButtonLevels.Secondary,
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPurchaseCreditModal = true))
                              }, null, 8, ["level"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 mr-4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "ma-0" }, {
                                default: _withCtx(() => [
                                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "token-text-h5 my-auto" }, " Burn rate ", -1)),
                                  _createVNode(_component_CustomButton, {
                                    class: "ml-auto",
                                    text: "Edit limits",
                                    level: _ctx.$enums.ButtonLevels.Secondary,
                                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showEditLimits = true))
                                  }, null, 8, ["level"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, { class: "ma-0 token-text-h4 fit-content" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_PriceDisplay, {
                                    price: _ctx.burnRate?.attributes?.amount ? parseInt(_ctx.burnRate?.attributes?.amount) : 0,
                                    currency: _ctx.currency
                                  }, null, 8, ["price", "currency"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, { class: "ma-0" }, {
                                default: _withCtx(() => [
                                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "token-text-regular mr-1" }, "Limit", -1)),
                                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$helpers.prices.format(_ctx.customer?.attributes?.monthly_burn_rate_limit, _ctx.currency)), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "ma-0 token-text-h5" }, {
                                default: _withCtx(() => [
                                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "token-text-h5 my-auto" }, " Projected usage ", -1)),
                                  _createVNode(_component_CustomButton, {
                                    class: "ml-auto",
                                    text: "Edit limits",
                                    level: _ctx.$enums.ButtonLevels.Secondary,
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showEditLimits = true))
                                  }, null, 8, ["level"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, { class: "ma-0 token-text-h4 fit-content" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_PriceDisplay, {
                                    price: _ctx.projectedUsage,
                                    currency: _ctx.currency
                                  }, null, 8, ["price", "currency"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, { class: "ma-0" }, {
                                default: _withCtx(() => [
                                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "token-text-regular mr-1" }, "Limit", -1)),
                                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$helpers.prices.format(_ctx.customer?.attributes?.monthly_hard_limit, _ctx.currency)), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0 token-text-h5" }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" Ressource usage ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mt-6 mb-4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_FlatGauge, {
                            values: _ctx.flatGaugeValues,
                            colors: _ctx.flatGaugeColors
                          }, null, 8, ["values", "colors"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0 mx-2 mb-6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "ma-0" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "ma-0 ml-auto mr-0" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Indicator, {
                                            color: _ctx.flatGaugeColors[0]
                                          }, null, 8, ["color"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { class: "pa-0" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                                          _createTextVNode(" Ressource used ")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(_ctx.used, _ctx.currency)), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "ma-0 d-flex" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "ma-0 ml-auto mr-0" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Indicator, {
                                            color: _ctx.flatGaugeColors[1]
                                          }, null, 8, ["color"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { class: "pa-0 my-auto" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                                          _createTextVNode(" Projected usage ")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(_ctx.projectedUsage,_ctx.currency)), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "pa-0" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { class: "ma-0" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "ma-0 ml-auto mr-0" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Indicator, {
                                            color: _ctx.flatGaugeColors[2]
                                          }, null, 8, ["color"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { class: "pa-0" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                                          _createTextVNode(" Usage hard limit ")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(_ctx.customer?.attributes.monthly_hard_limit,_ctx.currency)), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.isProjectedOverLimit)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "ma-0 alert-msg mt-4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { class: "pa-0 fit-content mr-2 mt-1" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, { icon: "warning" })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { class: "pa-0" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                                      _createTextVNode(" Projected use is over the monthly spending hard limit. You might face service loss. ")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                                      _createTextVNode(" You can increase the limit or reduce your resource size to avoid service loss. ")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}