import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, createSlots as _createSlots } from "vue"
import _imports_0 from '@/assets/illustrations/coins-success.svg'


const _hoisted_1 = { class: "token-text-color-error ma-2" }
const _hoisted_2 = { class: "token-text-regular token-text-color-secondary" }
const _hoisted_3 = { class: "token-text-medium ml-auto" }
const _hoisted_4 = { class: "token-text-medium ml-auto" }
const _hoisted_5 = { class: "token-text-medium ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_FransysSignature = _resolveComponent("FransysSignature")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_WidgetBillingDetails = _resolveComponent("WidgetBillingDetails")!
  const _component_StripeWidgetPaymentMethods = _resolveComponent("StripeWidgetPaymentMethods")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_CustomNumberInput = _resolveComponent("CustomNumberInput")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_PaymentMethodsDisplay = _resolveComponent("PaymentMethodsDisplay")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    closeOnExternalClick: true,
    "onUpdate:visible": _ctx.closeModal,
    loading: _ctx.loadingDraft,
    fullScreen: _ctx.fullScreen
  }, _createSlots({
    content: _withCtx(() => [
      _createVNode(_component_v_col, { class: "pa-4" }, {
        default: _withCtx(() => [
          (_ctx.showEditBillingDetails)
            ? (_openBlock(), _createBlock(_component_WidgetBillingDetails, {
                key: 0,
                customer: _ctx.customer,
                onSuccess: _ctx.onEditBillingDetails
              }, null, 8, ["customer", "onSuccess"]))
            : (_ctx.showAddOtherPaymentMethod)
              ? (_openBlock(), _createBlock(_component_StripeWidgetPaymentMethods, {
                  key: 1,
                  customer: _ctx.customer,
                  showCancel: true,
                  onSuccess: _ctx.onAddPaymentMethod,
                  onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddOtherPaymentMethod = false))
                }, null, 8, ["customer", "onSuccess"]))
              : (_ctx.showSuccess)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content mt-12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          src: _imports_0,
                          height: "64px",
                          width: "96px",
                          class: "mx-auto mb-6"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 token-text-h5 mx-auto fit-content" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.creditAmountToAdd) + " credits purchased ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 mx-auto fit-content mt-6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Spinner)
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" Credit amount ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomNumberInput, {
                          modelValue: _ctx.creditAmountToAdd,
                          "onUpdate:modelValue": [
                            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.creditAmountToAdd) = $event)),
                            _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadingModalCTA = true))
                          ],
                          class: "full-width",
                          onDelayedUpdate: _ctx.editDraft
                        }, null, 8, ["modelValue", "onDelayedUpdate"])
                      ]),
                      _: 1
                    }),
                    (_ctx.errs?.quantity)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.errs.quantity, (err, index) => {
                          return (_openBlock(), _createBlock(_component_v_row, {
                            class: "ma-0 mt-2 token-background-color-error rounded-border",
                            key: 'err-quantity-' + index
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, _toDisplayString(err), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, "After this purchase you will have " + _toDisplayString(_ctx.$helpers.prices.format(parseInt(_ctx.customer?.attributes?.balance) + (_ctx.creditAmountToAdd * 100))) + " credits.", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 my-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Divider, { class: "full-width" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 token-text-medium mb-2" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" Payment methods ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PaymentMethodsDisplay, {
                          paymentMethods: _ctx.paymentMethods,
                          selectable: true,
                          selected: _ctx.paymentMethodSelected,
                          "onUpdate:selected": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.paymentMethodSelected) = $event))
                        }, null, 8, ["paymentMethods", "selected"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 mb-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomButton, {
                          text: "Add payment method",
                          level: _ctx.$enums.ButtonLevels.Secondary,
                          onClick: _ctx.onClickAddOtherPaymentMethod
                        }, null, 8, ["level", "onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 my-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Divider, { class: "full-width" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createElementVNode("span", { class: "token-text-medium" }, "Summary", -1)
                      ])),
                      _: 1
                    }),
                    (_ctx.draftInvoiceCredit?.attributes.amount_subtotal)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 1,
                          class: "ma-0 mt-2"
                        }, {
                          default: _withCtx(() => [
                            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "token-text-regular" }, "Credits", -1)),
                            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$helpers.prices.format(_ctx.draftInvoiceCredit?.attributes.amount_subtotal, _ctx.draftInvoiceCredit?.attributes.currency)), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.draftInvoiceCredit?.attributes.amount_tax)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 2,
                          class: "ma-0 mt-2"
                        }, {
                          default: _withCtx(() => [
                            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "token-text-regular" }, "Tax", -1)),
                            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$helpers.prices.format(_ctx.draftInvoiceCredit?.attributes.amount_tax, _ctx.draftInvoiceCredit?.attributes.currency)), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.draftInvoiceCredit?.attributes.amount_total)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 3,
                          class: "ma-0 mt-2"
                        }, {
                          default: _withCtx(() => [
                            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "token-text-regular" }, "Final price", -1)),
                            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$helpers.prices.format(_ctx.draftInvoiceCredit?.attributes.amount_total, _ctx.draftInvoiceCredit?.attributes.currency)), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_row, { class: "ma-0 my-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Divider, { class: "full-width" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Switch, {
                          modelValue: _ctx.switchPromoValue,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.switchPromoValue) = $event))
                        }, null, 8, ["modelValue"]),
                        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "token-text-regular ml-2" }, "Promo code", -1))
                      ]),
                      _: 1
                    }),
                    (_ctx.switchPromoValue)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 4,
                          class: "ma-0 mt-4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CustomTextInput, {
                              modelValue: _ctx.promotionCode,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.promotionCode) = $event)),
                              class: "full-width",
                              onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.promotionCode ? _ctx.editDraft() : '')),
                              placeholder: "Promo code"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomButton, {
                          text: _ctx.loadingModalCTA ? '' : 'Purchase ' + _ctx.creditAmountToAdd + ' credits',
                          level: _ctx.$enums.ButtonLevels.Primary,
                          class: "full-width",
                          onClick: _ctx.onPurchaseCredit,
                          loading: _ctx.loadingModalCTA,
                          disabled: _ctx.disabledCTA
                        }, null, 8, ["text", "level", "onClick", "loading", "disabled"])
                      ]),
                      _: 1
                    })
                  ], 64))
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (_ctx.fullScreen)
      ? {
          name: "headerFullScreen",
          fn: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 my-auto mx-12" }, {
              default: _withCtx(() => [
                (!_ctx.showSuccess)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      class: "pa-0 fit-content my-auto",
                      style: {"position":"absolute","top":"60px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomButton, {
                          text: "Back",
                          icon: "caret-left",
                          level: _ctx.$enums.ButtonLevels.Secondary,
                          onClick: _ctx.closeModal
                        }, null, 8, ["level", "onClick"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FransysSignature, {
                      vertical: true,
                      class: "ma-auto"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.fullScreen && !_ctx.showSuccess)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createVNode(_component_v_row, { class: "ma-0 token-text-h4" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("div", null, " Purchase credits ", -1)
              ])),
              _: 1
            })
          ]),
          key: "1"
        }
      : (!_ctx.showSuccess)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createVNode(_component_v_row, { class: "ma-0" }, {
                default: _withCtx(() => [
                  _cache[8] || (_cache[8] = _createElementVNode("div", null, " Purchase credits ", -1)),
                  _createVNode(_component_CustomButton, {
                    class: "ml-auto",
                    level: _ctx.$enums.ButtonLevels.SmallIcon,
                    icon: "x",
                    onClick: _ctx.closeModal
                  }, null, 8, ["level", "onClick"])
                ]),
                _: 1
              })
            ]),
            key: "2"
          }
        : undefined
  ]), 1032, ["visible", "onUpdate:visible", "loading", "fullScreen"]))
}