import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-2 token-text-color-secondary"
}
const _hoisted_2 = {
  key: 0,
  class: "ml-2 token-text-color-secondary"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_PriceDisplay = _resolveComponent("PriceDisplay")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Modal, {
      visible: _ctx.visible,
      loading: _ctx.loading,
      onClickExternal: _ctx.onClose
    }, {
      header: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-n2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomTextInput, {
              modelValue: _ctx.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              class: "mr-0",
              style: {"width":"calc(100% - 32px)"},
              plain: true,
              max: 30
            }, null, 8, ["modelValue"]),
            _createVNode(_component_CustomButton, {
              class: "ml-auto mr-2 my-auto fit-content",
              level: _ctx.$enums.ButtonLevels.SmallIcon,
              icon: "x",
              onClick: _ctx.onClose
            }, null, 8, ["level", "onClick"])
          ]),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-4 ma-0 flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 my-auto fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0 token-text-medium token-text-color-primary" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PriceDisplay, {
                      price: _ctx.totalPriceMin,
                      price2: _ctx.totalPriceMax,
                      currency: _ctx.customer?.attributes.currency
                    }, null, 8, ["price", "price2", "currency"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 fit-content ml-auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "ma-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.Tertiary,
                      text: "Cancel",
                      class: "mr-2",
                      onClick: _ctx.cancel
                    }, null, 8, ["level", "onClick"]),
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.Secondary,
                      text: _ctx.textAccept,
                      class: "mr-2",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.accept(false))),
                      status: _ctx.buttonStateSave,
                      loading: _ctx.saving,
                      disabled: _ctx.disableBtnEdit
                    }, null, 8, ["level", "text", "status", "loading", "disabled"]),
                    _createVNode(_component_CustomButton, {
                      level: _ctx.$enums.ButtonLevels.Primary,
                      text: 'Deploy cluster',
                      icon: 'publish',
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.accept(true))),
                      status: _ctx.buttonStatePublish,
                      loading: _ctx.saving,
                      disabled: _ctx.disableBtnPublish
                    }, null, 8, ["level", "status", "loading", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_ctx.isTrialing)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "ma-4 pa-4 alert-trial"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "pa-0" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Cluster are not editable with the free plan ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Upgrade your plan to edit the cluster or enable auto scaling ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomButton, {
                          level: _ctx.$enums.ButtonLevels.Primary,
                          class: "full-width",
                          onClick: _ctx.upgradePlan,
                          loading: _ctx.upgradePlanLoading
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Upgrade plan ")
                          ])),
                          _: 1
                        }, 8, ["level", "onClick", "loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_row, { class: "ma-0 ml-4 mt-2 mb-2 token-text-regular" }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Region ")
          ])),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mx-4 mb-6" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomSelect, {
              placeholder: "Select a value",
              items: _ctx.regionsListItems,
              modelValue: _ctx.regionsActualValue,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.regionsActualValue) = $event)),
                _ctx.onSelectRegion
              ]
            }, null, 8, ["items", "modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        (_ctx.isAutoScalling)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-6 token-text-regular" }, {
                default: _withCtx(() => [
                  _cache[11] || (_cache[11] = _createElementVNode("div", null, " Worker nodes ", -1)),
                  (_ctx.masterNodes.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " + " + _toDisplayString(_ctx.masterNodes.length) + " master node(s) ", 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 mx-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Slider, {
                    values: _ctx.nbNodesActualRange,
                    step: 1,
                    min: 1,
                    max: _ctx.maxNbNodes,
                    "onUpdateValue:values": _cache[2] || (_cache[2] = ($event: any) => (_ctx.nbNodesActualRange = $event)),
                    range: true,
                    disabled: _ctx.isTrialing
                  }, null, 8, ["values", "max", "disabled"])
                ]),
                _: 1
              }),
              (_ctx.masterNodes.length)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    class: "ma-0 py-2 mx-4 mb-6 master-nodes-info bar"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, {
                            icon: "info",
                            color: "black",
                            class: "mt-1 mx-2"
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { class: "pa-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode(" Master nodes added to this cluster to prevent service loss ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode(" These items are required for your cluster to work properly ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-6 token-text-regular" }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode(" Nodes Performance ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 mx-4" }, {
                default: _withCtx(() => [
                  (_ctx.typologies[_ctx.nodesPerfActualRange[0]])
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        class: "pa-2 custom-thumb"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "ma-0" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.typologies[_ctx.nodesPerfActualRange[0]].attributes.name), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.typologies[_ctx.nodesPerfActualRange[0]].attributes.cores) + " CPUs ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.typologies[_ctx.nodesPerfActualRange[0]].attributes.memory) + " RAM ", 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.regionsActualValue?.value)
                            ? (_openBlock(), _createBlock(_component_v_row, {
                                key: 0,
                                class: "ma-0 token-text-color-secondary"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(parseInt(_ctx.typologies[_ctx.nodesPerfActualRange[0]].attributes.prices[_ctx.regionsActualValue.value].amount), _ctx.typologies[_ctx.nodesPerfActualRange[0]].attributes.prices[_ctx.regionsActualValue.value].currency)), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_col, { class: "pa-0" }),
                  (_ctx.typologies[_ctx.nodesPerfActualRange[0]])
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 1,
                        class: "pa-2 custom-thumb"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "ma-0" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.typologies[_ctx.nodesPerfActualRange[1]].attributes.name), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.typologies[_ctx.nodesPerfActualRange[1]].attributes.cores) + " CPUs ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.typologies[_ctx.nodesPerfActualRange[1]].attributes.memory) + " RAM ", 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.regionsActualValue?.value)
                            ? (_openBlock(), _createBlock(_component_v_row, {
                                key: 0,
                                class: "ma-0 token-text-color-secondary"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$helpers.prices.format(parseInt(_ctx.typologies[_ctx.nodesPerfActualRange[1]].attributes.prices[_ctx.regionsActualValue.value].amount), _ctx.typologies[_ctx.nodesPerfActualRange[1]].attributes.prices[_ctx.regionsActualValue.value].currency)), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 mx-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Slider, {
                    values: _ctx.nodesPerfActualRange,
                    step: 1,
                    min: 0,
                    max: _ctx.typologies.length - 1,
                    hideThumbLabels: true,
                    "onUpdateValue:values": _cache[3] || (_cache[3] = ($event: any) => (_ctx.nodesPerfActualRange = $event)),
                    range: true,
                    disabled: _ctx.isTrialing
                  }, null, 8, ["values", "max", "disabled"])
                ]),
                _: 1
              })
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-6 token-text-regular" }, {
                default: _withCtx(() => [
                  _cache[15] || (_cache[15] = _createElementVNode("div", null, " Worker nodes ", -1)),
                  (_ctx.masterNodes.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, " + " + _toDisplayString(_ctx.masterNodes.length) + " control node(s) ", 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 mx-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Slider, {
                    values: _ctx.nbNodesActualRange,
                    step: 1,
                    min: 1,
                    max: _ctx.maxNbNodes,
                    "onUpdateValue:values": _cache[4] || (_cache[4] = ($event: any) => {_ctx.nbNodesActualRange[0] = $event; _ctx.nbNodesActualRange[1] = $event}),
                    disabled: _ctx.isTrialing
                  }, null, 8, ["values", "max", "disabled"])
                ]),
                _: 1
              }),
              (_ctx.masterNodes.length)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    class: "ma-0 py-2 mx-4 mb-6 master-nodes-info bar"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, {
                            icon: "info",
                            color: "black",
                            class: "mt-1 mx-2"
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { class: "pa-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                              _createTextVNode(" Control nodes are added to this cluster for reliability ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                              _createTextVNode(" They are managed automatically, but do impact the cost ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_row, { class: "ma-0 ml-4 mb-2 token-text-regular" }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode(" Nodes Performance ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "ma-0 mx-4 mb-2" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typologies, (typology, index) => {
                    return (_openBlock(), _createBlock(_component_v_col, {
                      class: "pa-0",
                      key: 'typo-' + index,
                      cols: "6"
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.isTypoAbsent(typology))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["card-typo", (index%2 === 0 ? ' mr-2 ' : ' ml-2 ') + (_ctx.nodesPerfActualRange[0] === index ? ' selected ' : '') + (_ctx.isTypoDisabled(typology) ? ' disabled ' : '')]),
                              onClick: ($event: any) => (_ctx.onClickTypoCard(typology, index))
                            }, [
                              _createVNode(_component_v_row, { class: "ma-0" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(typology.attributes.name), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(typology.attributes.cores) + " CPUs ", 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_row, { class: "ma-0 token-text-color-secondary" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(typology.attributes.memory) + " RAM ", 1)
                                ]),
                                _: 2
                              }, 1024)
                            ], 10, _hoisted_3))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ], 64))
      ]),
      _: 1
    }, 8, ["visible", "loading", "onClickExternal"])
  ]))
}