<template>
  <Teleport to="body">
    <v-col class="modal-container pa-0" :class="{'visible': visible, transparent: transparent, 'full-screen' :fullScreen}" @mousedown="onExternalClick">
      <v-row class="header-full-screen token-text-medium ma-0 full-width" v-if="fullScreen">
        <slot name="headerFullScreen"></slot>
      </v-row>
      <v-row class="ma-0 fit-content mx-auto">
        <v-col class="modal-bar bar pa-0"  @mousedown.stop="" :style="computedStyle">
          <div class="header token-text-medium" v-if="$slots['header']">
            <slot name="header"></slot>
          </div>
          <div class="content">
            <template v-if="loading">
              <Spinner class="ma-auto"/>
            </template>
            <template v-else>
              <slot></slot>
              <slot name="content"></slot>
            </template>
            
          </div>
          <div class="footer" v-if="$slots['footer'] && !loading">
            <slot name="footer"></slot>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </Teleport>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Spinner from '@/components/UIElements/Spinner.vue'

class Props {
  visible?: boolean = prop({
    required: false,
  });
  transparent?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  width?: string = prop({
    required: false,
  });
  closeOnExternalClick?: boolean = prop({
    required: false,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Spinner
  },
})
export default class Modal extends Vue.with(Props) {


  get computedStyle ():string {
    return '' + (this.width ? `width: ${this.width} !important;min-width: ${this.width} !important;max-width: ${this.width} !important;` : '') 
  }

  onExternalClick() {
    if(!this.fullScreen) {
      this.$emit('clickExternal')
      if(this.closeOnExternalClick) {
        this.$emit('update:visible', false)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';

.modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  position: fixed;
  z-index: 10000 !important;
  background: $color-neutral-grey-60;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, padding 0.2s ease-in-out, background 0.2s ease-in-out;
  backdrop-filter: blur(4px);
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
    &.transparent {
      background: transparent !important;
      .modal-bar {
        background: rgba(255, 255, 255, 0.24) !important;
        div {
          opacity: 0;
        }
      }
    }
  }

  .header {
    background: $color-neutral-grey-4;
    padding: 12px;
  }

  .modal-bar {
    transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out;
    div {
      transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out;
    }
    z-index: 100001 !important;
    backdrop-filter: blur(1px) !important;
    width: auto;
    max-width: 676px;
    min-width: 573px;
    margin: auto;
    display: flex;
    background: white !important;
    flex-direction: column;
    display: flex;
    .footer {
      border-top: 1px solid $color-neutral-grey-12;
      display: flex;
    }
  }

  .content {
    min-height: 50px;
    max-height: calc(100vh - 152px);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &.full-screen {
    background: white;
    .modal-bar {
      height: 100%;
      border:none !important;
      box-shadow: none !important;
      max-width: none;
    }
    .header {
      background: none;
    }
    .header-full-screen {
      height: 160px;
      max-height: 160px;
      min-height: 160px;
    }
  }

}

</style>