import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, withModifiers as _withModifiers, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "popover-top pa-0" }
const _hoisted_2 = { class: "popover-right" }
const _hoisted_3 = {
  key: 1,
  class: "popover-bottom pa-4 py-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["popover-container", {'opened': _ctx.visible, transparent: _ctx.transparent, positionerOnly: _ctx.positionerOnly}]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onExternalClick && _ctx.onExternalClick(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["popover", {ready:_ctx.isPlaced, bar:!_ctx.positionerOnly}]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
        ref: "popoverDOM",
        style: _normalizeStyle('top:'+ _ctx.top + 'px; left:' + _ctx.left + 'px')
      }, [
        (!_ctx.positionerOnly)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["arrow", {
            top: _ctx.position === _ctx.POSITION.BOTTOM,
            bottom: _ctx.position === _ctx.POSITION.TOP,
            left: _ctx.position === _ctx.POSITION.RIGHT,
            right: _ctx.position === _ctx.POSITION.LEFT
          }]),
              style: _normalizeStyle('top:'+ _ctx.arrowTop + 'px; left:' + _ctx.arrowLeft + 'px;')
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["inner-arrow", {
            top: _ctx.position === _ctx.POSITION.BOTTOM,
            bottom: _ctx.position === _ctx.POSITION.TOP,
            left: _ctx.position === _ctx.POSITION.RIGHT,
            right: _ctx.position === _ctx.POSITION.LEFT
          }])
              }, null, 2)
            ], 6))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_Spinner, {
                  key: 0,
                  class: "ma-auto"
                }))
              : (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  class: "pa-0"
                }, {
                  default: _withCtx(() => [
                    (_ctx.$slots.header)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: _normalizeClass(["popover-title pa-4 pb-0", {'pb-4': !_ctx.$slots.content}])
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                          ]),
                          _: 3
                        }, 8, ["class"]))
                      : _createCommentVNode("", true),
                    (_ctx.$slots.content)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 1,
                          class: "popover-content pa-4"
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                          ]),
                          _: 3
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }))
          ])
        ]),
        (_ctx.$slots.footer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ], 6)
    ], 2)
  ]))
}