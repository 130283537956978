import API from "@/api/wrapper"
import store from ".."
import { APICustomer, APIInvoice, APIMoney, APIPaymentMethod, APIPrice } from "@/typesAPI"
import helpers from "@/utils/helpers"

type State = {
  burnRate: APIMoney | null,
  currentBillingCycle:APIPrice[] | null,
  invoices:APIInvoice[] | null,
  paymentMethods:APIPaymentMethod[] | null,
  draftInvoiceCredit:APIInvoice | null,
}

const state: State = {
  burnRate: null,
  currentBillingCycle: null,
  invoices: null,
  paymentMethods: null,
  draftInvoiceCredit: null
}


const getters = {
  getCustomer: ():APICustomer => {
    return store.getters['user/getCustomer']
  },
  getBurnRate: (state:State) => {
    if(state.burnRate === null && store.getters['user/getCustomer']?.id) {
      API.billing.getBurnRate(store.getters['user/getCustomer']?.id)
      .then((res:APIMoney) => {
        state.burnRate = res
      })
      return 0
    }
    return state.burnRate
  },
  getPaymentMethods: (state:State) => {
    if(state.paymentMethods === null && store.getters['user/getCustomer']?.id) {
      API.billing.getPaymentMethods(store.getters['user/getCustomer']?.id)
      .then((res:APIPaymentMethod[]) => {
        state.paymentMethods = res.sort((a, b) => a.attributes.priority - b.attributes.priority)
      })
      return []
    }
    return state.paymentMethods
  },
  getCurrentBillingPeriod: (state:State) => {
    let invoices:APIInvoice[] | null = store.getters['billing/getInvoices']
    if(invoices) {
      invoices = invoices.filter((invoice:APIInvoice) => invoice.attributes.type === 'tally')
    } 
    const customer:APICustomer = store.getters['user/getCustomer']
    const startDateString:string = invoices === null || !invoices.length ? customer?.attributes?.created_at : invoices[invoices.length - 1].attributes.end
    const startCycle:Date = new Date(startDateString)
    const endCycle:Date = new Date(startCycle.setMonth(startCycle.getMonth() + 1));
    return {start: new Date(startDateString), end : endCycle}

  },
  getProjectedUsage: (state:State) => {
    const burnRate:APIMoney = store.getters['billing/getBurnRate']
    if(!burnRate) {
      return 0
    }
    const burnRateValue:number = parseInt(burnRate?.attributes?.amount)
    const ressourceUsed:number = store.getters['billing/getRessourceUsed']
    const billingPeriod:{start:Date, end:Date} = store.getters['billing/getCurrentBillingPeriod']
    const msLeftInPeriod =  billingPeriod.end.getTime() - new Date().getTime()
    return ((burnRateValue * msLeftInPeriod) / 2592000000) + ressourceUsed
  },
  getRessourceUsed: (state:State) => {
    let ret = 0
    store.getters['billing/getCurrentBillingCycle']?.forEach((priceAttributes:APIPrice) => {
      ret += parseInt(priceAttributes.attributes.amount)
    })
    return ret
  },
  getCurrentBillingCycle: (state:State) => {
    if(state.currentBillingCycle === null && store.getters['user/getCustomer']?.id) {
      API.billing.getCurrentBillingCycle(store.getters['user/getCustomer']?.id)
      .then((res:APIPrice[]) => {
        state.currentBillingCycle = res
      })
      return []
    }
    return state.currentBillingCycle
  },
  getInvoices: (state:State) => {
    if(state.invoices === null && store.getters['user/getCustomer']?.id) {
      API.billing.getInvoices(store.getters['user/getCustomer']?.id)
      .then((res:any) => {
        state.invoices = res
      })
      return []
    }
    return state.invoices
  },
  getDraftInvoiceCredit: (state:State) => {
    return state.draftInvoiceCredit
  },
}

const mutations = {
  SET_DRAFT_INVOICE_CREDIT: (state:State, newVal:APIInvoice | null) => {
    if(newVal && state.draftInvoiceCredit) {
      if(helpers.ressources.isNewerThan(newVal, state.draftInvoiceCredit)) {
        state.draftInvoiceCredit = newVal
      }
    } else {
      state.draftInvoiceCredit = newVal
    }
  },
  UPDATE_INVOICE: (state:State, newVal:APIInvoice) => {
    if(!state.invoices)  {
      state.invoices = [newVal]
    } else {
      helpers.ressources.addMultipleRessourceToArray(state.invoices, [newVal])
    }
  },
  DELETE_INVOICE: (state:State, newVal:APIInvoice) => {
    if(state.invoices)  {
      state.invoices.forEach((fv, index) => {
        if(fv.id === newVal.id && helpers.ressources.isNewerThan(newVal, fv) && state.invoices) {
          state.invoices.splice(index,1)
        }
      })
    }
  },
  INVALIDATE_INVOICES: (state:State) => {
    state.invoices = null
  },
  INVALIDATE_PAYMENT_METHODS: (state:State) => {
    state.paymentMethods = null
  },
}

const actions = {
  setDraftInvoiceCredit: (context:any, newVal:APIInvoice | null) => {
    context.commit('SET_DRAFT_INVOICE_CREDIT', newVal)
  },
  updateInvoice: (context:any, newVal:APIInvoice) => {
    context.commit('UPDATE_INVOICE', newVal)
  },
  deleteInvoice: (context:any, newVal:APIInvoice) => {
    context.commit('UPDATE_INVOICE', newVal)
  },
  invalidateInvoices: (context:any) => {
    context.commit('INVALIDATE_INVOICES')
  },
  invalidatePaymentMethods: (context:any) => {
    context.commit('INVALIDATE_PAYMENT_METHODS')
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}