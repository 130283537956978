import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "block-detail-content-initiated" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ListElement = _resolveComponent("ListElement")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_FieldGroup = _resolveComponent("FieldGroup")!
  const _component_FieldBox = _resolveComponent("FieldBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedBoxes, (box) => {
      return (_openBlock(), _createBlock(_component_FieldBox, {
        key: 'box-container-'+box.id,
        title: box.attributes.name,
        deployed: _ctx.boxesOpen[box.id],
        "onUpdate:deployed": ($event: any) => ((_ctx.boxesOpen[box.id]) = $event),
        vertical: _ctx.getFieldValuesByBoxID(box.id).length !== 1,
        box: box,
        disabled: _ctx.getBoxDisabledState(box)
      }, {
        value: _withCtx(() => [
          _createVNode(_component_v_col, { class: "pa-0" }, {
            default: _withCtx(() => [
              (!Object.keys(_ctx.getGroupedFieldsValuesByBox(box)).length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getFieldValuesByBoxID(box.id, true), (relationshipFieldValue) => {
                    return (_openBlock(), _createBlock(_component_v_row, {
                      key: 'box-value-' +relationshipFieldValue.id,
                      id: 'box-value-' +relationshipFieldValue.id,
                      class: "ma-0"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.getFieldsDefinitionsByIDValue(relationshipFieldValue.id).attributes.should_show_in_recap)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_ctx.getFieldValuesByBoxID(box.id).length !== 1)
                                ? (_openBlock(), _createBlock(_component_v_col, {
                                    key: 0,
                                    class: "pa-0 fit-content token-text-regular token-text-color-secondary pb-2",
                                    cols: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.fieldSummary[relationshipFieldValue.id]?.noTitle || _ctx.fieldSummary[relationshipFieldValue.id]?.isURL ? "" : _ctx.getFieldsDefinitionsByIDValue(relationshipFieldValue.id).attributes.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true),
                              _createVNode(_component_v_col, {
                                class: _normalizeClass(["pa-0 mr-0 token-text-regular value-container", _ctx.fieldSummary[relationshipFieldValue.id] && _ctx.fieldSummary[relationshipFieldValue.id].isURL ? 'ml-0' : 'ml-auto pl-2'])
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.fieldSummary[relationshipFieldValue.id])
                                    ? (_openBlock(), _createBlock(_component_ListElement, {
                                        key: 0,
                                        isURL: _ctx.fieldSummary[relationshipFieldValue.id].isURL,
                                        iconRigth: _ctx.fieldSummary[relationshipFieldValue.id].isURL ? 'share' : '',
                                        logo: _ctx.fieldSummary[relationshipFieldValue.id].logo,
                                        text: _ctx.fieldSummary[relationshipFieldValue.id].text,
                                        badgeState: _ctx.fieldSummary[relationshipFieldValue.id].badgeState,
                                        badgeLabel: _ctx.fieldSummary[relationshipFieldValue.id].badgeLabel,
                                        badgeOutlined: _ctx.fieldSummary[relationshipFieldValue.id].badgeOutlined,
                                        noHover: true,
                                        alignRight: !_ctx.fieldSummary[relationshipFieldValue.id].isURL,
                                        dense: true
                                      }, null, 8, ["isURL", "iconRigth", "logo", "text", "badgeState", "badgeLabel", "badgeOutlined", "alignRight"]))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createTextVNode(" ")
                                      ], 64))
                                ]),
                                _: 2
                              }, 1032, ["class"])
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["id"]))
                  }), 128))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGroupedFieldsValuesByBox(box), (fieldValueGrouped, groupId) => {
                return (_openBlock(), _createBlock(_component_v_row, {
                  key: 'box-value-' +groupId,
                  id: 'box-value-' +groupId,
                  class: "ma-0"
                }, {
                  default: _withCtx(() => [
                    (_ctx.groupSummary[groupId])
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_v_col, {
                            class: "pa-0 fit-content token-text-regular token-text-color-secondary pb-2",
                            cols: "12"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.groupSummary[groupId].title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_col, {
                            class: _normalizeClass(["pa-0 mr-0 token-text-regular value-container", _ctx.groupSummary[groupId] && _ctx.groupSummary[groupId].isURL ? 'ml-0' : 'ml-auto pl-2'])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_ListElement, {
                                isURL: _ctx.groupSummary[groupId].isURL,
                                iconRigth: _ctx.groupSummary[groupId].isURL ? 'share' : '',
                                logo: _ctx.groupSummary[groupId].logo,
                                text: _ctx.groupSummary[groupId].text,
                                badgeState: _ctx.groupSummary[groupId].badgeState,
                                badgeLabel: _ctx.groupSummary[groupId].badgeLabel,
                                badgeOutlined: _ctx.groupSummary[groupId].badgeOutlined,
                                noHover: true,
                                alignRight: !_ctx.groupSummary[groupId].isURL,
                                dense: true
                              }, null, 8, ["isURL", "iconRigth", "logo", "text", "badgeState", "badgeLabel", "badgeOutlined", "alignRight"])
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["id"]))
              }), 128))
            ]),
            _: 2
          }, 1024)
        ]),
        deployed: _withCtx(() => [
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGroupedFieldsValuesByBox(box), (fieldValueGrouped, groupId) => {
              return (_openBlock(), _createBlock(_component_FieldGroup, {
                key: 'group-'+groupId,
                deployed: _ctx.groupsOpen[groupId],
                "onUpdate:deployed": ($event: any) => ((_ctx.groupsOpen[groupId]) = $event),
                groupId: groupId
              }, {
                value: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fieldValueGrouped, (fieldValue) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          key: 'box-value-' +fieldValue.id,
                          id: 'box-value-' +fieldValue.id,
                          class: "ma-0"
                        }, {
                          default: _withCtx(() => [
                            (fieldValue?.parent.attributes.should_show_in_recap)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (fieldValueGrouped.length !== 1)
                                    ? (_openBlock(), _createBlock(_component_v_col, {
                                        key: 0,
                                        class: "pa-0 fit-content token-text-regular token-text-color-secondary pb-2",
                                        cols: "12"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(fieldValue?.parent.attributes.name), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_v_col, {
                                    class: _normalizeClass(["pa-0 mr-0 token-text-regular value-container", _ctx.fieldSummary[fieldValue.id] && _ctx.fieldSummary[fieldValue.id].isURL ? 'ml-0' : 'ml-auto pl-2'])
                                  }, {
                                    default: _withCtx(() => [
                                      (_ctx.fieldSummary[fieldValue.id])
                                        ? (_openBlock(), _createBlock(_component_ListElement, {
                                            key: 0,
                                            isURL: _ctx.fieldSummary[fieldValue.id].isURL,
                                            iconRigth: _ctx.fieldSummary[fieldValue.id].isURL ? 'share' : '',
                                            logo: _ctx.fieldSummary[fieldValue.id].logo,
                                            text: _ctx.fieldSummary[fieldValue.id].text,
                                            badgeState: _ctx.fieldSummary[fieldValue.id].badgeState,
                                            badgeLabel: _ctx.fieldSummary[fieldValue.id].badgeLabel,
                                            badgeOutlined: _ctx.fieldSummary[fieldValue.id].badgeOutlined,
                                            alignRight: !_ctx.fieldSummary[fieldValue.id].isURL,
                                            noHover: true,
                                            dense: true
                                          }, null, 8, ["isURL", "iconRigth", "logo", "text", "badgeState", "badgeLabel", "badgeOutlined", "alignRight"]))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createTextVNode(" ")
                                          ], 64))
                                    ]),
                                    _: 2
                                  }, 1032, ["class"])
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["id"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                deployed: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fieldValueGrouped, (fieldValue) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: 'fv-'+fieldValue.id
                    }, [
                      (fieldValue && !fieldValue?.attributes.should_hide && _ctx.blockAPI && fieldValue.relationships?.block.data.id === _ctx.blockAPI.id)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.myFieldComponents[fieldValue?.parent.attributes.type]), {
                            key: 0,
                            class: "component-wrapper",
                            definitionAttributes: fieldValue?.parent.attributes,
                            fieldValue: fieldValue,
                            disabled: fieldValue?.attributes.is_read_only,
                            solo: _ctx.getFieldValuesByBoxID(box.id).length === 1,
                            fieldSummary: _ctx.fieldSummary[fieldValue.id],
                            "onUpdate:fieldSummary": ($event: any) => ((_ctx.fieldSummary[fieldValue.id]) = $event),
                            groupSummary: _ctx.groupSummary[groupId],
                            "onUpdate:groupSummary": ($event: any) => ((_ctx.groupSummary[groupId]) = $event),
                            onChangeValue: _ctx.onChange
                          }, null, 40, ["definitionAttributes", "fieldValue", "disabled", "solo", "fieldSummary", "onUpdate:fieldSummary", "groupSummary", "onUpdate:groupSummary", "onChangeValue"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["deployed", "onUpdate:deployed", "groupId"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getNotGroupedFieldsValuesByBox(box), (fieldValue) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: 'fv-'+fieldValue.id
              }, [
                (fieldValue && !fieldValue?.attributes.should_hide && _ctx.blockAPI && fieldValue.relationships?.block.data.id === _ctx.blockAPI.id)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.myFieldComponents[fieldValue?.parent.attributes.type]), {
                      key: 0,
                      class: "component-wrapper",
                      definitionAttributes: fieldValue?.parent.attributes,
                      fieldValue: fieldValue,
                      disabled: fieldValue?.attributes.is_read_only,
                      solo: _ctx.getFieldValuesByBoxID(box.id).length === 1,
                      fieldSummary: _ctx.fieldSummary[fieldValue.id],
                      "onUpdate:fieldSummary": ($event: any) => ((_ctx.fieldSummary[fieldValue.id]) = $event),
                      onChangeValue: _ctx.onChange
                    }, null, 40, ["definitionAttributes", "fieldValue", "disabled", "solo", "fieldSummary", "onUpdate:fieldSummary", "onChangeValue"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ]),
        _: 2
      }, 1032, ["title", "deployed", "onUpdate:deployed", "vertical", "box", "disabled"]))
    }), 128))
  ]))
}