<template>
  <div class="detail-field-social-account" >
    <ShellDetailField :disabled="disabled" :solo="solo">
      <template #content>
        <v-col class="pa-0 detail-field-social-account-content" :class="{noAccount: !socialAccount}">
          <template v-if="socialAccount">
            <v-row class="ma-0 ma-2">
              <v-col class="pa-0 fit-content">
                  <Avatar :src="socialAccount.attributes.avatar"/>
              </v-col>
              <v-col class="pa-0 pl-4 d-flex">
                <v-row class="my-auto ml-2">
                  <template v-if="forcedSocialAccount">
                    <v-col class="pa-0">
                      <div class="token-text-medium">{{ socialAccount.attributes.provider }}</div>
                      <div class="token-text-micro">{{ socialAccount.attributes.nickname }}</div>
                    </v-col>
                  </template>
                  <template v-else>
                    {{ socialAccount.attributes.nickname }}
                  </template>
                </v-row>
              
              </v-col>
              <v-col class="pa-0 my-auto" style="position:relative;">
                <CustomButton ref="menuButton" class="ml-auto mr-0 px-3" :level="forcedSocialAccount ? $enums.ButtonLevels.Secondary : $enums.ButtonLevels.Tertiary" @click="burgerOpened = !burgerOpened" icon="burger" >
                </CustomButton>
                <DropdownMenu 
                    :items="burgerItems"
                    v-model:show="burgerOpened"
                    :maxContent="true"
                    @update:modelValue="onBurgerMenuItemClick"
                    :target="$refs.menuButton"
                  />
                
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-col class="pa-4">
              <v-row class="ma-0 pb-4">
                <!-- Mettre les images ici -->
                <v-img :src="socialAccountProviderIntegrationImg"  height="72px"/>
              </v-row>
              <v-row class="ma-0 token-text-medium pb-2">
                Connect Fransys to {{ socialAccountProviderName }} to start hosting.
              </v-row>
              <v-row class="ma-0 token-text-regular token-text-color-secondary pb-4">
                Fransys will only use your account to import your code and generate a working CI.
              </v-row>
              <v-row class="ma-0">
                <CustomButton class="connect-button" :level="$enums.ButtonLevels.Secondary" @click="connect">Connect {{ socialAccountProviderName }}</CustomButton>
              </v-row>
            </v-col>
          </template>
        </v-col>
      </template>
    </ShellDetailField>
  </div>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import { APIFieldDefinition, APIFieldValue, APIUrlRedirection } from '@/typesAPI'
import ShellDetailField from '@/components/Details/ShellDetailField.vue'
import {Props as ListElementProps} from '@/components/UIElements/ListElement.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Avatar from '@/components/UIElements/Avatar.vue';
import API from '@/api/wrapper';
import { APISocialAccount } from "@/typesAPI"
import { Watch } from 'vue-property-decorator';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { ListItem } from '@/components/UIElements/List.vue';

class Props {
  definitionAttributes?: APIFieldDefinition['attributes'] = prop({
    required: false,
  });
  fieldValue?: APIFieldValue = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  solo?: boolean = prop({
    required: false,
  });
  fieldSummary?: ListElementProps = prop({
    required: false,
  });
  forcedSocialAccount?: APISocialAccount = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    ShellDetailField,
    Avatar,
    DropdownMenu
  },
})
export default class DetailFieldSocialAccount extends Vue.with(Props) {

  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'disconnect',
      text: 'Disconnect',
    },
  ]
  callbackCode = ''


  onBurgerMenuItemClick (newVal:ListItem) {
    newVal.selected = false
    this.burgerOpened = false

    switch(newVal.id) {
      case 'disconnect' : 
        this.disconnect()
      break;
      case 'permissions' : 
        this.connect()
      break;
      default:
      break;
    }
  }

  @Watch('socialAccountProvider', {immediate: true})
  onSocialAccountProviderChange() {
    if(this.socialAccountProvider === 'github') {
      this.burgerItems.push({
        id: 'permissions',
        text: 'Manage permissions',
      })
    }
  }

  get socialAccountProvider():string {
    if(this.forcedSocialAccount) {
      return this.forcedSocialAccount.attributes.provider
    }
    return this.definitionAttributes?.meta?.provider ? this.definitionAttributes.meta.provider : ""
  }

  get socialAccountProviderIntegrationImg():string | undefined {
    try {
      return require('@/assets/illustrations/' + this.socialAccountProvider +'_integration.svg') + ""
    } catch {
      return undefined
    }
  }

  get socialAccountProviderName():string {
    return this.definitionAttributes?.meta?.provider_name ? this.definitionAttributes.meta.provider_name : ""
  }

  mounted(): void {
    this.$emit('update:fieldSummary', {
      text: this.preSelected,
    })

    this.getSocialAccount()
  }

  get preSelected(): string {
    return this.socialAccount ? this.socialAccount?.attributes?.nickname : '-'
  }

  getSocialAccount() {
    if(this.fieldValue && this.fieldValue.attributes.value && !this.socialAccount) {
      API.social.get(this.fieldValue.attributes.value)
      .then((socialAccount:APISocialAccount) => {
        this.$store.dispatch('user/setSocialAccount', {provider: this.socialAccountProvider, socialAccount: socialAccount})
      })
    }
  }

  connect() {    
    const paramsProjectIdOrigin = this.$route.params.projectId ? `projectId=${this.$route.params.projectId}` : ''
    const paramsBlockIdOrigin = this.$route.params.blockId ? `|blockId=${this.$route.params.blockId}` : ''
    const paramsTabIdOrigin = this.$route.params.tabId ? `|tabId=${this.$route.params.tabId}` : ''
    const paramsBoxIdOrigin = this.$route.params.boxId ? `|boxId=${this.$route.params.boxId}` : ''
    const origin = window.location.origin
    const params = new URLSearchParams(`state=${paramsProjectIdOrigin}${paramsBlockIdOrigin}${paramsTabIdOrigin}${paramsBoxIdOrigin}`)
    const newUrl = `${origin}/socialAccounts/${this.socialAccountProvider}/callback?${params.toString()}`

    API.social.connect(this.$store.getters['user/getUser'].id, this.socialAccountProvider, newUrl)
    .then((urlRedirection:APIUrlRedirection) => {
      window.location.href = urlRedirection.attributes.url
    })
  }

  disconnect() {
    if(this.fieldValue) {
      API.social.delete(this.fieldValue.attributes.value)
      .then(() => {
        this.$store.dispatch('user/deleteSocialAccount', this.socialAccountProvider)
        this.$emit('changeValue',this.definitionAttributes?.key, null, this.fieldValue?.id)
      })
    }
  }

  @Watch('socialAccount', {immediate: true})
  onSocialAccountChange() {
    if(this.socialAccount) {
      this.$emit('update:fieldSummary', {
        text: this.socialAccount.attributes.nickname
      })
      if(this.socialAccount.id !==  this.fieldValue?.attributes?.value) {
        this.$emit('changeValue',this.definitionAttributes?.key, this.socialAccount.id, this.fieldValue?.id)
      }
    }
  }

  get socialAccount():APISocialAccount | undefined {
    return this.forcedSocialAccount ? this.forcedSocialAccount : this.$store.getters['user/getSocialAccountsByProvider'][this.socialAccountProvider]
  }

}
</script>


<style lang="scss" scoped>
@import '@/css/variables';
.detail-field-social-account {
  .detail-field-social-account-content {
    border: $color-neutral-grey-12 1px solid;
    border-radius: 8px;
    &.noAccount {
      background: $color-neutral-grey-4;
      border: none;
    }
  }
  .connect-button {
    width: 100%;
  }
}
</style>
