import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "header token-text-medium"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 1,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_v_col, {
      class: _normalizeClass(["modal-container pa-0", {'visible': _ctx.visible, transparent: _ctx.transparent, 'full-screen' :_ctx.fullScreen}]),
      onMousedown: _ctx.onExternalClick
    }, {
      default: _withCtx(() => [
        (_ctx.fullScreen)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "header-full-screen token-text-medium ma-0 full-width"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "headerFullScreen", {}, undefined, true)
              ]),
              _: 3
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_row, { class: "ma-0 fit-content mx-auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "modal-bar bar pa-0",
              onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
              style: _normalizeStyle(_ctx.computedStyle)
            }, {
              default: _withCtx(() => [
                (_ctx.$slots['header'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_Spinner, {
                        key: 0,
                        class: "ma-auto"
                      }))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                      ], 64))
                ]),
                (_ctx.$slots['footer'] && !_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["style"])
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["class", "onMousedown"])
  ]))
}