<template>
    <Modal :visible="visible" :closeOnExternalClick="true" @update:visible="closeModal" width="780px" :fullScreen="fullScreen">
      <template #headerFullScreen v-if="fullScreen">
        <v-row class="ma-0 my-auto mx-12">
          <v-col class="pa-0 fit-content my-auto" style="position: absolute; top: 60px;">
            <CustomButton text="Back" icon="caret-left" :level="$enums.ButtonLevels.Secondary" @click="closeModal"/>
          </v-col>
          <v-col class="pa-0">
            <FransysSignature :vertical="true" class="ma-auto"/>
          </v-col>
        </v-row> 
      </template>
      <template #header v-if="fullScreen">
        <v-row class="ma-0 token-text-h4">
          <div>
            Choose spending limits
          </div>
        </v-row>      
      </template>
      <template #header v-else>
        <v-row class="ma-0">
          <div>
            Edit limits
          </div>
          <CustomButton class="ml-auto" :level="$enums.ButtonLevels.SmallIcon" icon="x" @click="closeModal"/>
        </v-row>      
      </template>
      <template #content>
        <v-row class="pa-4 ma-0">
          <v-col class="pa-0">
            <v-row class="ma-0 mt-2 ">
              <v-col class="pa-0">
                <v-row class="ma-0 token-text-regular">
                  Burn rate limit
                </v-row>
                <v-row class="ma-0 mt-2">
                  <CustomNumberInput class="full-width" v-model="burnRateLimit" :min="0" :max="customer?.attributes?.monthly_system_burn_rate_limit / 100"/>
                </v-row>
                <v-row class="ma-0 token-text-micro mt-2">
                  When your burn rate reaches this amount we will reduce your resource usage.
                </v-row>
                <v-row class="ma-0 mt-2">
                  <v-col class="pa-0 fit-content mr-2 my-auto">
                    <Icon icon="info" />
                  </v-col>
                  <v-col class="pa-0">
                    Maximum amount : {{$helpers.prices.format(customer?.attributes?.monthly_system_burn_rate_limit, currency)}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pa-0 ml-8">
                <v-row class="ma-0 token-text-regular">
                  Monthly spending hard limit
                </v-row>
                <v-row class="ma-0 mt-2">
                  <CustomNumberInput class="full-width" v-model="hardLimit" :min="0" :max="customer?.attributes?.monthly_system_hard_limit / 100"/>
                </v-row>
                <v-row class="ma-0 token-text-micro mt-2">
                  When your usage reaches this amount we will undeploy all your ressources to prevent overdraft.
                </v-row>
                <v-row class="ma-0 mt-2">
                  <v-col class="pa-0 fit-content mr-2 my-auto">
                    <Icon icon="info" />
                  </v-col>
                  <v-col class="pa-0">
                    Maximum amount : {{$helpers.prices.format(customer?.attributes?.monthly_system_hard_limit, currency)}}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="ma-0 mt-4">
              <CustomButton :level="$enums.ButtonLevels.Primary" class="full-width" @click="saveLimitsChange" :loading="loading" :status="buttonState">
                {{buttonText}}
              </CustomButton>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </Modal>
</template>

<script lang="ts">
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { Watch } from 'vue-property-decorator';
import { APICustomer } from '@/typesAPI';
import API from '@/api/wrapper'
import FransysSignature from '@/components/UIElements/FransysSignature.vue'

class Props {
  visible?: boolean = prop({
    required: false,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomNumberInput,
    Icon,
    FransysSignature
  },
})
export default class ModalEditLimits extends Vue.with(Props) {
  loading = false
  burnRateLimit = 0
  hardLimit = 0
  buttonState = ""
  buttonText = "Save limits"

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get currency():string {
    return this.customer?.attributes?.currency
  }
  
  saveLimitsChange() {
    this.loading = true
    API.billing.editCustomer(this.customer.id,{monthly_burn_rate_limit: this.burnRateLimit * 100, monthly_hard_limit: this.hardLimit * 100})
    .then(() => {
      this.loading = false
      this.buttonState = this.$enums.ButtonStates.Success
      this.buttonText = "Success"
      setTimeout(() => {
        this.buttonState = ""
        this.buttonText = "Save limits"
        this.closeModal()
      },3000)
    })
  }

  closeModal() {
    this.$emit('update:visible',false)
  }

  @Watch('customer', {immediate: true})
  onCustomerChange() {
    if(this.customer) {
      this.burnRateLimit = this.customer?.attributes.monthly_burn_rate_limit / 100
      this.hardLimit = this.customer?.attributes.monthly_hard_limit / 100
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/css/variables';
</style>
